export function PlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={props?.iconWidth ? props?.iconWidth : 24}
      height={24}
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path fill="#000000" d="M12 5v14M5 12h14" />
    </svg>
  );
}
