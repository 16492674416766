/**
 * Helper function which returns the sequenced
 * array with the help for variants for PDP
 * @param {object} product
 * @param {number} variantSelectedIndex
 * @return {array}
 */
export const getProductImagesForPDP = (product, variantSelectedIndex) => {
  let output = [];
  product = JSON.parse(JSON.stringify(product));

  if (product?.variants?.length && variantSelectedIndex > -1) {
    const _tempImg = product.variants.reduce((acc, curr, variantIndex) => {
      if (curr.images) {
        curr.images.forEach((image) => (image.variant_name = curr.variant_name));
        if (variantIndex === variantSelectedIndex) acc = [...curr.images, ...acc];
        else acc = [...acc, ...curr.images];
      }
      return acc;
    }, []);

    if (product.images) output = [..._tempImg, ...product.images];
    else output = _tempImg;
  } else {
    output = product.images;
  }

  // if output is null or output is an empty array
  if (output === null || (Array.isArray(output) && !output.length)) {
    output = [{ image_url: product.image_url, thumbnail_url: product.thumbnail_url }];
  }

  return output;
};
