import {
  SAVE_STORE_INFO,
  SAVE_B2B_RFQ_INPUT_FIELDS,
  TOGGLE_RFQ_FORM_MODAL,
  UPDATE_THEME_INFO,
} from '../actionTypes';
import { InputFieldData } from '../../containers/ProductDescription/Components/B2b/utils/constants';

interface StoreDetailsAddress {
  store_id: number;
  address_1: string;
  address_2: string;
  latitude: number;
  longitude: number;
  city: string;
  google_address: string;
  pincode: string;
  landmark: string;
  city_id: number;
  state: string;
  visibility: 1 | 2;
}

interface StoreDetailsOwner {
  store_id: number;
  user_id: number;
  phone: string;
  role: string;
  verify_phone: number;
  language_id: number;
  first_name: string;
  last_name: string;
  email_id: string;
  sign_in_id: string;
  photo: string;
  permissions: number[];
}

interface StoreDetailsServices {
  store_id: number;
  store_flag: number;
  delivery_flag: number;
  pickup_flag: number;
  listing_flag: number;
  partner_delivery_flag: number;
  min_order_value: number;
  mdr_flag: number;
  delivery_charge_type: number;
  free_delivery_above: number;
  delivery_price: number;
  delivery_charge_min: number;
  delivery_charge_max: number;
  delivery_time_approx: string;
  is_setup: number;
  request_callback: number;
  payment_method: number;
  notification_flag: number;
  merchant_cart_notification: number;
  customer_cart_notification: number;
  emi_flag: number;
  service_sell_flag: number;
  store_type_flag: number;
  gst_flag: number;
  gst_activated_flag: number;
  members_only_access: number;
  notify_me_enabled: number;
  waba_notification_flag: number;
  cod_charge_flag: number;
  cod_charge_value: number;
  cod_charge_offest_value: number;
}

interface StoreDetailsDomainInfo {
  store_id: number;
  domain: string;
  redirect_to: string;
  domain_type: number;
  expiry_date: string;
  status: number;
  site_id: number;
}

interface StoreDetailsGSTInfo {
  store_id: number;
  gst_number: string;
  is_gst_same: number;
  is_gst_included: number;
  default_gst: number;
  default_hsn: string;
  signature: string;
  gst_status: number;
  gst_address: string;
  gst_business_name: string;
  pincode: string;
}

interface StoreDetailsStoreInfo {
  name: string;
  domain: string;
  store_url: string;
  logo_image: string;
  favicon: string;
  reference_store_id: number;
  description: string;
  merchant_shiprocket_status: number;
  total_credits: number;
  non_premium_ad_url_desktop_vertical: string;
  non_premium_ad_url_desktop_horizontal: string;
  non_premium_ad_url_mobile: string;
}

interface StoreDetailsThemeComponent {
  id: number;
  sub_type: string;
  name: string;
  order: number;
  widget_id: string;
  config?: Record<string, any>;
  html?: string;
  images?:
    | {
        id: number;
        image_url: string;
        image_href: string;
        height: number;
        width: number;
        is_desktop: boolean;
        order: 0;
      }[]
    | null;
  banner_fixed_ratio?: number;
}

export interface StoreTheme {
  theme_id: number;
  store_theme_id: number;
  colors: {
    id: number;
    name: string;
    image_url: string;
    primary_color: string;
    secondary_color: string;
  };
  cta_config: {
    banner_cta: string;
    header_cta: string;
    pdp_cta: string;
    postpaid_cart_cta: string;
    my_orders: string;
  };
  roundness: number;
  category: string;
  expiry: string;
  is_demo: number;
  theme_class: number;
  createlist_flag: number;
  mb_flag: string;
  custom_theme_flag: number;
  additional_page_flag: number;
  category_aspect_ratio: number;
  product_aspect_ratio: number;
  banner_fixed_ratio: number;
  product_image_quality_flag: number;
  is_quick_view: number;
  components: {
    Body: StoreDetailsThemeComponent[];
    Footer: StoreDetailsThemeComponent[];
    Header: StoreDetailsThemeComponent[];
    PLP: StoreDetailsThemeComponent[];
  };
}

export interface StoreDetails {
  store_id: number;
  store_name: string;
  domain: string;
  display_phone: string;
  description: string;
  store_url: string;
  logo_image: string;
  favicon: string;
  reference_store_id: number;
  spotlight_flag: number;
  score: number;
  kyc_status: number;
  gst_status: number;
  gst_number: string;
  premium: number;
  bank_account_id: number;
  subscription_flag: number;
  source: string;
  created_at: string;
  status: number;
  address: StoreDetailsAddress;
  owner: StoreDetailsOwner;
  services: StoreDetailsServices;
  configs: {
    store_id: number;
    rewards: number;
    promos: number;
    payouts: number;
    bank_update: number;
    catalog: number;
  };
  domain_info: StoreDetailsDomainInfo;
  store_businesses: {
    business_id: number;
    business_name: string;
    image: 'https://cdn.dotpe.in/kiranaStatic/image/Others05012021.png';
  }[];
  gst_info: StoreDetailsGSTInfo;
  store_info: StoreDetailsStoreInfo;
  categories: null;
  bank_details: null;
  theme: StoreTheme | null;
  store_premium_theme: {
    theme_id: number;
    store_theme_id: number;
    colors: {
      id: number;
      name: string;
      image_url: string;
      primary_color: string;
      secondary_color: '';
    };
    cta_config: null;
    roundness: number;
    category: string;
    expiry: string;
    is_demo: number;
    theme_class: number;
    createlist_flag: number;
    mb_flag: string;
    custom_theme_flag: number;
    additional_page_flag: number;
    category_aspect_ratio: number;
    product_aspect_ratio: number;
    banner_fixed_ratio: number;
    product_image_quality_flag: number;
    is_quick_view: number;
    components: {
      Body: StoreDetailsThemeComponent[];
      Footer: StoreDetailsThemeComponent[];
      Header: StoreDetailsThemeComponent[];
      PLP: StoreDetailsThemeComponent[];
    };
  };
  store_marketing_tags: {
    store_id: number;
    facebook_pixel: string;
    google_analytics: string;
    google_ads: string;
    g_tag: string;
    gmc_verification: string;
    fb_domain_verification: string;
  };
  constants: null;
  is_ecomm_plus_active: boolean;
  social_media_profile_details: {
    media_count: number;
    media_detail: {
      id: number;
      name: string;
      image_url: string;
      profile_url: string;
    }[];
  };
  redirect_url?: string;
}

interface StoreReducer {
  timeStamp: number;
  b2bRfqInputFields: InputFieldData[];
  openRfqFormModal: boolean;
  store: StoreDetails;
}
const initialState: StoreReducer = {
  timeStamp: 0,
  b2bRfqInputFields: [],
  openRfqFormModal: false,
  store: {
    results: [],
  } as any,
};

export const storeReducer = (
  state: StoreReducer = initialState,
  action
): StoreReducer => {
  switch (action.type) {
    case SAVE_STORE_INFO: {
      // reorder the subcomponents of the theme components according to order key value.
      const themeComponents = action.data?.theme?.components || {};
      for (const componentKey of Object.keys(themeComponents)) {
        const component = themeComponents[componentKey];
        if (Array.isArray(component)) {
          component.sort((subComp1, subComp2) => subComp1?.order - subComp2?.order);
        }
      }

      return { ...state, store: action.data, timeStamp: Date.now() };
    }
    case SAVE_B2B_RFQ_INPUT_FIELDS:
      return { ...state, b2bRfqInputFields: action.data, timeStamp: Date.now() };
    case TOGGLE_RFQ_FORM_MODAL:
      return {
        ...state,
        openRfqFormModal: !state.openRfqFormModal,
        timeStamp: Date.now(),
      };
    case UPDATE_THEME_INFO:
      return { ...state, store: { ...state?.store, theme: action.data } };
    default:
      return state;
  }
};
