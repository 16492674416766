import {
  SAVE_GST_INVOICE,
  SAVE_ORDER,
  SAVE_ORDER_DETAILS,
  SAVE_ORDER_LIST_COMPLETE,
  SAVE_ORDER_LIST_CONFIG,
  SAVE_ORDER_LIST_PENDING,
  SAVE_PARENT_ORDER_DETAILS,
} from '../actionTypes';

const initialState = {
  data: {},
  showLoader: true,
  orderDetails: {
    items: [],
    store_id: '',
    order_id: '',
    merchant_id: '',
    phone: '',
    order_type: '',
    status: '',
    status_message: '',
    payment_status: 0,
    display_status: 'new',
  },
  parentOrderDetails: {},
  orderListsPending: [],
  orderListsComplete: [],
  completeOrderPageConfig: {
    pageNum: 1,
    isNext: true,
  },
  pendingOrderPageConfig: {
    pageNum: 1,
    isNext: true,
  },
  gstInvoice: '',
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORDER:
      return { ...state, data: action.data };
    case SAVE_ORDER_DETAILS:
      return { ...state, orderDetails: action.data };
    case SAVE_ORDER_LIST_COMPLETE:
      return {
        ...state,
        orderListsComplete: action.replace
          ? action.data || []
          : [...state.orderListsComplete, ...(action.data || [])],
      };
    case SAVE_ORDER_LIST_PENDING:
      return {
        ...state,
        orderListsPending: action.replace
          ? action.data || []
          : [...state.orderListsPending, ...(action.data || [])],
      };
    case SAVE_ORDER_LIST_CONFIG:
      return {
        ...state,
        ...action.data,
      };
    case SAVE_GST_INVOICE:
      return { ...state, gstInvoice: action.data };
    case SAVE_PARENT_ORDER_DETAILS:
      return { ...state, parentOrderDetails: action.data };
    default:
      return state;
  }
};

export default OrderReducer;
