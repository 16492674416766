export const HEADING_TYPE = [
  {
    type: 'font',
    subTitle: '',
    title: 'Fonts',
  },
  {
    type: 'appearance',
    subTitle: '',
    title: 'Appearance',
  },
  {
    type: 'loader',
    subTitle: '',
    title: 'Loader',
  },
  {
    type: 'chevrons',
    subTitle: '',
    title: 'Chevrons',
  },
];

export const BTN_TYPE = 'btnType';

export const APPEARANCE_CARD_TYPE = {
  SHARP: 'Sharp',
  PILL: 'Pill',
  ROUNDED: 'Rounded',
};

export const LOADER_TYPE = [
  {
    name: 'Logo 1',
    type: 'logo1',
  },
  {
    name: 'Logo 2',
    type: 'logo2',
  },
  {
    name: 'Loader 1',
    type: 'loader1',
  },
  {
    name: 'Loader 2',
    type: 'loader2',
  },
  {
    name: 'Loader 3',
    type: 'loader3',
  },
  {
    name: 'Loader 4',
    type: 'loader4',
  },
  {
    name: 'Loader 5',
    type: 'loader5',
  },
];

export const GLOBAL_SETTING_CONFIG = {
  fonts: {
    base_config: {
      selectedId: 'default1',
      heading: {
        fontFamily: "Inter",
        fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
        letterSpacing: "0px"
      },
      body: {
        fontFamily: "Inter",
        fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
        letterSpacing: "0px"
      },
      buttonInherit: 'body',
    },
    leftSection: {
      sectionHeading: {
        heading: 'Font styles',
        subHeading: 'Applicable to all the texts tagged from h1 to h6',
      },
      defaultStyles: [
        {
          type: 'default',
          id: 'default1',
          heading: {
            fontFamily: "Inter",
            fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
            letterSpacing: "0px"
          },
          body: {
            fontFamily: "Inter",
            fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
            letterSpacing: "0px"
          },
          buttonInherit: 'body',
        },
        {
          type: 'default',
          id: 'default2',
          heading: {
            fontFamily: 'Inria Serif',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Amaranth&family=Dosis&family=Elsie:wght@900&family=IBM+Plex+Serif:wght@600&family=Inria+Serif:wght@700&family=Merriweather:wght@700&family=Rajdhani:wght@500&family=Sevillana&family=Tangerine:wght@700&family=Unica+One&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: "Inter",
            fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
            letterSpacing: "0px"
          },
          buttonInherit: 'body',
        },
        {
          type: 'default',
          id: 'default3',
          heading: {
            fontFamily: "Montserrat",
            fontCdn: "https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap",
            letterSpacing: "0px"
          },
          body: {
            fontFamily: "Montserrat",
            fontCdn: "https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap",
            letterSpacing: "0px"
          },
          buttonInherit: 'body',
        },
        {
          type: 'default',
          id: 'default4',
          heading: {
            fontFamily: 'DM Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'DM Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          type: 'default',
          id: 'default5',
          heading: {
            fontFamily: "Josefin Sans",
            fontCdn: "https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap",
            letterSpacing: "0px"
          },
          body: {
            fontFamily: 'Manrope',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500&family=Manrope:wght@300;400;500;600;700;800&family=Mina:wght@400;700&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          type: 'default',
          id: 'default6',
          heading: {
            fontFamily: "Playfair Display",
            fontCdn: "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap",
            letterSpacing: "0px"
          },
          body: {
            fontFamily: "Albert Sans",
            fontCdn: "https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap",
            letterSpacing: "0px"
          },
          buttonInherit: 'body',
        },
      ],
      customStyles: [],
      allStyles: [],
    },
  },
  appearance: {
    base_config: {
      btnType: 'Sharp',
      btnRadius: 0,
      inputRadius: 0,
      imageCardRadius: 0,
    },
    leftSection: {
      button: {
        sectionHeading: {
          heading: 'Button',
          subHeading: 'Will be applicable to solid and outline button',
        },
        cardMultiselect: {
          label: 'Button roundness',
          key: 'btnRadius',
          selectedIndex: 0,
          types: [
            {
              type: 'Sharp',
              sprite: 'wb-sprite ic-button-sharp',
            },
            {
              type: 'Rounded',
              sprite: 'wb-sprite ic-button-rounded',
            },
            {
              type: 'Pill',
              sprite: 'wb-sprite ic-button-pill',
            },
          ],
        },
        slider: {
          type: 'buttons',
          label: 'Border radius',
          key: 'btnRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'button',
          valKey: 'btnRadius',
        },
      },
      inputField: {
        sectionHeading: {
          heading: 'Input fields',
          subHeading: 'Forms, search, newsletter.',
        },
        slider: {
          type: 'inputs',
          label: 'Border radius',
          key: 'inputRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'input',
          valKey: 'inputRadius',
        },
      },
      imageCards: {
        sectionHeading: {
          heading: 'Images, Cards.',
          subHeading: 'Product, category, collection.',
        },
        slider: {
          type: 'imageCards',
          label: 'Border radius',
          key: 'imageCardRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'imageCard',
          valKey: 'imageCardRadius',
        },
      },
    },
  },
  loader: {
    base_config: {
      loaderId: 'loader1',
      color: '#E2E8F8',
    },
    leftSection: {
      colorPicker: {
        key: 'color',
        text: 'color',
        displayVal: 'Loader colour',
      },
    },
  },
  chevrons: {
    base_config: {
      chevronId: 0,
    },
    leftSection: {
      chevronsList: [
        {
          type: 0,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/5TyxjncZ.webp',
        },
        {
          type: 1,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/rFcpxKuf.webp',
        },
        {
          type: 2,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/O9PrLsxd.webp',
        },
        {
          type: 3,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/Jl0wGLHx.webp',
        },
      ],
    },
  },
};

export const GLOBAL_STYLE = {
  fonts: {
    type: "default",
    id: "default1",
    heading: {
      fontFamily: "Inter",
      fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
      letterSpacing: "0px"
    },
    body: {
      fontFamily: "Inter",
      fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
      letterSpacing: "0px"
    },
    buttonInherit: 'body',
  },
  buttons: {
    style: {
      borderRadius: '0px',
    },
    borderType: 'Sharp',
  },
  inputs: {
    style: {
      borderRadius: '0px',
    },
  },
  imageCards: {
    style: {
      borderRadius: '0px',
    },
  },
  appLoader: {
    loaderId: 'loader1',
    color: '#E2E8F8',
  },
  chevrons: {
    selected: 0,
  },
};

export const FONT_DROPDOWN = [
  {
    fontFamily: 'Montserrat',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap',
  },
  {
    fontFamily: 'Inria Serif',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Amaranth&family=Dosis&family=Elsie:wght@900&family=IBM+Plex+Serif:wght@600&family=Inria+Serif:wght@700&family=Merriweather:wght@700&family=Rajdhani:wght@500&family=Sevillana&family=Tangerine:wght@700&family=Unica+One&display=swap',
  },
  {
    fontFamily: "Inter",
    fontCdn: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
  },
  {
    fontFamily: 'DM Sans',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
  },
  {
    fontFamily: "Josefin Sans",
    fontCdn: "https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap",
  },
  {
    fontFamily: 'Manrope',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500&family=Manrope:wght@300;400;500;600;700;800&family=Mina:wght@400;700&display=swap',
  },
  {
    fontFamily: "Playfair Display",
    fontCdn: "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap",
  },
  {
    fontFamily: "Albert Sans",
    fontCdn: "https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap",
  },
];

export const HEADING_DROPDOWN = {
  label: 'Heading Font',
  subLabel: 'Applicable to all the texts tagged from h1 to h6',
  key: 'heading',
  data: FONT_DROPDOWN,
  placeholder: '',
};

export const HEADING_SLIDER = {
  label: 'Heading font letter spacing',
  subLabel: 'Space between letters',
  min: -1,
  max: 3,
  suffix: 'px',
  key: 'heading',
  step: 0.5,
};

export const BODY_DROPDOWN = {
  label: 'Body text font',
  subLabel:
    'Applicable to all the texts tagged as paragraph and texts over website like header, footer, Item cards, cart page, etc.',
  key: 'body',
  data: FONT_DROPDOWN,
  placeholder: '',
};

export const BODY_SLIDER = {
  label: 'Body text letter spacing',
  subLabel: 'Space between letters',
  min: -1,
  max: 3,
  suffix: 'px',
  key: 'body',
  step: 0.5,
};

export const FONT_TABS = [
  {
    title: 'All',
    val: 'all',
  },
  {
    title: 'Default styles',
    val: 'default',
  },
  {
    title: 'Custom styles',
    val: 'custom',
  },
];

export const BUTTON_MULTISELECT = {
  label: 'Button text font',
  subLabel: 'Select font for button text',
  data: [
    {
      text: 'Body text font',
      value: 'body',
      itemLevelClass: 'tw-w-[50%]',
    },
    {
      text: 'Heading font',
      value: 'heading',
      itemLevelClass: 'tw-w-[50%]',
    },
  ],
};
