/**
 * Coupons related Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import {
  applyCoupon,
  saveCouponsList,
  setLoaderState,
  savePaymentOffersList,
  setCart,
  saveCart,
  setGlobalLoader,
} from '../actions';
import {
  FETCH_COUPONS_LIST,
  VERIFY_COUPON,
  FETCH_PAYMENT_OFFERS_LIST,
  VERIFY_PAYMENT_OFFER,
  FETCH_EVALUATED_COUPONS,
  GET_CART_PAGE_PROMOS,
  GET_ALL_PROMOS,
  GET_PROMO_DATA_BY_ID,
  GET_PROMOS_BY_TYPE_AND_TYPE_ID,
  GET_PROMO_ASSOCIATED_IDS,
  GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS,
  GET_BOGO_REQUIRED_PRODUCTS_IN_CART,
  GET_SATISFIED_BOGO_PROMOS,
  APPLY_PROMO,
} from '../actionTypes';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';
import { toastNotifyError } from '@/components/Common/Toast';
import store from '../store';

function* fetchCouponsList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getCouponsListByStoreId(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(saveCouponsList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* fetchEvaluatedCouponsList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.GET_EVALUATED_ACTIVE_CUSTOMER_COUPONS,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(saveCouponsList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* verifyCoupon(actions) {
  try {
    const userData = store.getState()?.userReducer?.data;
    actions.data.user_id = userData?.user_id;
    if (userData?.email) {
      actions.data.user_email = userData?.email;
    }
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.VERIFY_COUPON,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      if (promoResponse?.data?.data?.reject_reason) {
        actions.callback &&
          actions.callback({
            status: false,
            message: promoResponse?.data?.data?.reject_reason,
          });
      } else {
        yield put(applyCoupon(promoResponse.data.data));
        actions.callback && actions.callback({ status: true });
      }
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* fetchPaymentOfferList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getPaymentCouponsListByStoreId(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(savePaymentOffersList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* verifyPaymentOffer(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.VERIFY_PAYMENT_OFFER,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      if (promoResponse?.data?.data?.reject_reason) {
        actions.callback &&
          actions.callback({
            status: false,
            message: promoResponse?.data?.data?.reject_reason,
          });
      } else {
        actions.callback &&
          actions.callback({ status: true, data: promoResponse?.data?.data });
        yield put(setCart(promoResponse?.data?.data));
      }
    }
  } catch (error) {
    toastNotifyError("We can't verify your card currently. Please try again later.");
  } finally {
    yield put(setLoaderState(false));
  }
}

function* fetchAllPromos(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getAllPromos(actions?.data?.storeId, actions?.data?.userId),
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions?.callback?.(response?.data?.data || []);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch coupons');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch coupons');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchPromoDataById(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getPromoDataById(
        actions?.data?.promoId,
        actions?.data?.storeId
      ),
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions?.callback?.(response.data?.data);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch coupon data');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch coupon data');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchCartPagePromos(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.GET_CART_PAGE_PROMOS,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      const { eligible_coupons = [], ineligible_coupons = [] } = response.data.data || {};
      const allCoupons = [...eligible_coupons, ...ineligible_coupons];
      actions?.callback?.(allCoupons);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch coupons');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch coupons');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchPromosByTypeAndTypeIds(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.GET_PROMOS_BY_TYPE_AND_TYPE_ID,
      data: actions.data,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions?.callback?.(response.data?.data);
    } else {
      console.log(response?.data?.message || 'Unable to fetch coupons');
    }
  } catch (error) {
    console.log(error?.data?.message || 'Unable to fetch coupons');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* getPromoAssociatedIds(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.PROMO.GET_PROMO_ASSOCIATED_IDS,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions?.callback?.(response?.data?.data);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch promo associated ids');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch promo associated ids');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchPromoAssociatedProductsByTypeAndTypeIds(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.PROMO.GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS,
      parseToJson: true,
    });

    if (response?.data?.status) {
      const { is_next_page = false, items = [] } = response?.data?.data || {};
      actions?.callback?.(items, is_next_page);
    } else {
      console.log(response?.data?.message || 'Unable to fetch products');
    }
  } catch (error) {
    console.log(error?.data?.message || 'Unable to fetch products');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchBogoRequiredProductsInCart(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.PROMO.GET_REQUIRED_BOGO_PRODUCTS_IN_CART,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions?.callback?.(response?.data?.data);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch products');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch products');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* fetchSatisfiedBogoPromos(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.PROMO.GET_SATISFIED_BOGO_PROMOS,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions?.callback?.(response?.data?.data);
    } else {
      toastNotifyError(response?.data?.message || 'Unable to fetch promos');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to fetch promos');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

function* applyPromoOffer(actions) {
  try {
    yield put(setGlobalLoader(true));
    const response = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.PROMO.APPLY_COUPON,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions?.callback?.(response);
      if (!response.data.data?.reason) {
        yield put(saveCart(response?.data?.data?.cart));
      }
    } else {
      actions?.callback
        ? actions?.callback(response)
        : toastNotifyError(response?.data?.message || 'Unable to apply promo');
    }
  } catch (error) {
    toastNotifyError(error?.data?.message || 'Unable to apply promo');
  } finally {
    yield put(setGlobalLoader(false));
  }
}

export default function* root() {
  yield takeEvery(FETCH_EVALUATED_COUPONS, fetchEvaluatedCouponsList);
  yield takeEvery(FETCH_COUPONS_LIST, fetchCouponsList);
  yield takeEvery(VERIFY_COUPON, verifyCoupon);
  yield takeEvery(FETCH_PAYMENT_OFFERS_LIST, fetchPaymentOfferList);
  yield takeEvery(VERIFY_PAYMENT_OFFER, verifyPaymentOffer);

  yield takeEvery(GET_CART_PAGE_PROMOS, fetchCartPagePromos);
  yield takeEvery(GET_ALL_PROMOS, fetchAllPromos);
  yield takeEvery(GET_PROMO_DATA_BY_ID, fetchPromoDataById);
  yield takeEvery(GET_PROMOS_BY_TYPE_AND_TYPE_ID, fetchPromosByTypeAndTypeIds);
  yield takeEvery(GET_PROMO_ASSOCIATED_IDS, getPromoAssociatedIds);
  yield takeEvery(
    GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS,
    fetchPromoAssociatedProductsByTypeAndTypeIds
  );
  yield takeEvery(GET_BOGO_REQUIRED_PRODUCTS_IN_CART, fetchBogoRequiredProductsInCart);
  yield takeEvery(GET_SATISFIED_BOGO_PROMOS, fetchSatisfiedBogoPromos);
  yield takeEvery(APPLY_PROMO, applyPromoOffer);
}
