import checkIfThemePreview from './checkIfThemePreview';
import { ADDRESS_VISIBILITY } from './constants';

const DEFAULT_PHONE = '01244570330';
const DEFAULT_ADDRESS =
  '8th Floor, Tower-D, UNITECH CYBER PARK, Durga Colony, Sector 39, Gurugram, Haryana 122003';

const getFullStoreAddress = (address) => {
  return (
    address &&
    Object.keys(address).length > 0 &&
    `${address?.address_1 !== '' ? address?.address_1 : ''}${
      address?.city !== '' ? `, ${address?.city}` : ''
    }${address?.state !== '' ? `, ${address?.state}` : ''}${
      address?.pincode !== '' ? `, ${address?.pincode}` : ''
    }`
  );
};

const getAddressToShow = (isPreview, isAddressVisible, storeActualAddress) => {
  if (isPreview) {
    return storeActualAddress || DEFAULT_ADDRESS;
  } else {
    return isAddressVisible ? storeActualAddress : '';
  }
};

const getDummyOrActualContactDetails = (storeData, isFullAddress = false) => {
  const isAddressVisible = storeData?.address?.visibility !== ADDRESS_VISIBILITY?.HIDDEN;

  const isPreview = checkIfThemePreview();
  const storeActualAddress = isFullAddress
    ? getFullStoreAddress(storeData?.address)
    : storeData?.address?.address_1;

  const storeActualPhone = storeData?.display_phone || storeData?.owner?.phone;

  return {
    storeAddress: getAddressToShow(isPreview, isAddressVisible, storeActualAddress),
    storePhone: storeActualPhone || (isPreview ? DEFAULT_PHONE : ''),
  };
};

export default getDummyOrActualContactDetails;
