import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';
import {
  saveDetailedItemById,
  saveProductLoaderId,
  setCart,
  setChooseAndRepeatModalVisibility,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
  pushEventsLog,
  addProductPersonalizationData,
  openProductPersonalizationPopUp,
  setProductDataPersonalised,
  setPersonalizedProductCustomizationCount,
} from '@/redux/actions';
import { PATH, getRoute } from '@/utils/routes';
import { deviceWidth } from '@/utils/deviceWidth';
import { itemIndexFromCart } from '@/utils/itemIndexFromCart';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import { isShowPPModal } from '@/containers/ProductDescription/Utils/common';
import { useSSRSelector } from '@/redux/ssrStore';
import { IS_CLIENT } from '@/utils/checkRenderEnv';

export function useCartHandlers() {
  const dispatch = useDispatch();
  const router = useRouter();

  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  const {
    cartData,
    userData,
    commonReducer: { detailedItem },
    productData,
  } = useSelector((state) => ({
    cartData: state.cartReducer,
    userData: state.userReducer,
    commonReducer: state.commonReducer,
    productData: state.catalogReducer?.personalizedProduct?.productData,
  }));

  const [state, setState] = useState({
    itemCartIdx: -1, // item index for selected item
    cartCount: 0,
    showFullImage: {
      show: false,
      currentIndex: 0,
    },
    discountPercentage: 0,
    activeVariant: -1, // selected variant index if variant exists for item
    OSName: null,
    ownerNumber: null,
    isRedirect: false,
    cost: {
      discounted_price: 0,
      price: 0,
    },
    viewDescription: false,
    selectedVariantId: -1, // id of selected variant from variant list
    additionalRemark: [],
    showAuthModal: false,
    isMainProductLink: false,
  });

  const itemIndexInCart = itemIndexFromCart(cartData, productData);

  const onPersonalizeProductClick = () => {
    dispatch(openProductPersonalizationPopUp());
  };

  const getType = (type) => {
    switch (type) {
      case 'add':
      case 'inc':
        return 'inc';
      case 'dec':
      case 'save':
        return type;
      case 'confirm':
        return type;
      default:
        return '';
    }
  };

  const handleCart = useCallback(
    async (
      type,
      personalizedProductData = null,
      ppIdx,
      itemData = null,
      source = '',
      personalizationLocalState = null
    ) => {
      const item = itemData ?? productData ?? detailedItem;
      if (item?.variants_count > 0) {
        dispatch(saveProductLoaderId(item?.id));
        dispatch(saveDetailedItemById(item));
        const activeVariantId = IS_CLIENT && localStorage.getItem('activeVariantId');
        const activeVariant = item?.variants?.find(
          (el) => `${el?.variant_id}` === activeVariantId
        );

        if (
          state.activeVariant === -1 &&
          IS_CLIENT &&
          !localStorage.getItem('activeVariantId')
        ) {
          switch (type) {
            case 'add':
              dispatch(setVariantModalVisibility(true));
              break;
            case 'inc':
              dispatch(setChooseAndRepeatModalVisibility(true));
              break;
            case 'dec':
              dispatch(setSelectedVariantModalVisibility(true));
              break;
            default:
              break;
          }
        } else {
          if (type === 'inc' || type === 'add') {
            dispatch(setVariantModalVisibility(false));
          } else if (type === 'dec') {
            dispatch(setSelectedVariantModalVisibility(false));
          }
          const newCart = mutateVariantItemWithCartData(
            getType(type),
            item,
            activeVariant,
            cartData,
            personalizedProductData,
            ppIdx,
            personalizationLocalState
          );
          if (newCart) {
            newCart['store_id'] = storeInfo?.store_id;
            const idx = newCart?.items?.findIndex((ele) => ele?.item_id === item?.id);
            const cartItem = newCart?.items?.[idx];
            const updatedCartItems = cartItem?.variants_selected?.find(
              (el) => el?.variant_id === activeVariant?.variant_id
            );
            dispatch(setCart(newCart));
            if (item?.product_personalization) {
              dispatch(
                addProductPersonalizationData({
                  personalizationData: {
                    ...updatedCartItems,
                    id: cartItem?.id,
                    discounted_price: cartItem?.discounted_price,
                    item_name: cartItem?.item_name,
                  },
                  showPopUp: isShowPPModal(getType(type), updatedCartItems, source),
                  source: source,
                })
              );
            }
          }
        }
      } else {
        const newState = mutateItemObjectWithCartData(
          type,
          item,
          cartData,
          personalizedProductData,
          ppIdx,
          personalizationLocalState
        );
        if (newState) {
          newState['store_id'] = storeInfo?.store_id;
          dispatch(setCart(newState));
          const updatedCartItems = newState?.items?.find(
            (ele) => ele?.item_id === item?.id || ele?.item_id === item?.item_id
          );

          if (item?.product_personalization) {
            dispatch(
              addProductPersonalizationData({
                personalizationData: updatedCartItems,
                showPopUp: false,
                source: source,
              })
            );
          }
        } else {
          dispatch(setCart(cartData));
        }
      }

      dispatch(
        pushEventsLog({
          event_name: 'Cx_ATC',
          data: {
            store_id: storeInfo?.store_id,
            domain: storeInfo?.store_info?.domain,
            page: window.location.href,
            device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
            cx_number: userData?.data?.phone,
          },
        })
      );
    },
    [productData, cartData, storeInfo, userData]
  );

  const onBuyNowClick = useCallback(
    (personalizedProductData = {}) => {
      setState((state) => ({ ...state, isRedirect: true }));
      const itemIndex = itemIndexFromCart(cartData, productData);
      if (itemIndex === -1 || productData?.product_personalization) {
        handleCart('add', personalizedProductData, null, productData);
      }
      router.push(getRoute(PATH.CART, storeInfo?.store_info?.domain));
    },
    [handleCart, router, cartData, productData, storeInfo]
  );

  const handleIncreamentClick = (type, detailedItem = null) => {
    const itemData = detailedItem ?? productData;
    handleSetProductData(itemData);
    const activeVariantId = IS_CLIENT && localStorage.getItem('activeVariantId');

    if (itemData?.product_personalization) {
      handleSetProductCustomizationCount(itemData);
      const cartItem = cartData?.items?.find(
        (ele) => ele?.item_id === itemData?.id || ele?.item_id === itemData?.item_id
      );

      const findCartItem =
        itemData?.variants_count > 0
          ? cartItem?.variants_selected.find(
              (el) => `${el?.variant_id}` === activeVariantId
            )
          : cartItem;

      if (itemData?.variants_count > 0) {
        findCartItem = {
          ...findCartItem,
          id: cartItem?.id,
          amount: cartItem?.amount,
          item_name: cartItem?.item_name,
        };
      }

      dispatch(
        addProductPersonalizationData({
          personalizationData: findCartItem,
          actionType: 'inc',
        })
      );
    } else {
      handleCart(type);
    }
  };

  const handleDecreamentClick = (type, detailedItem = null, source = '') => {
    const itemData = detailedItem ?? productData;
    handleSetProductData(itemData);
    const activeVariantId = IS_CLIENT && localStorage.getItem('activeVariantId');
    if (itemData?.product_personalization) {
      handleSetProductCustomizationCount(itemData);
      const cartItem = cartData?.items?.find(
        (ele) => ele.item_id === itemData.id || ele.item_id === itemData.item_id
      );
      const findCartItem =
        itemData?.variants_count > 0
          ? cartItem?.variants_selected.find(
              (el) => `${el?.variant_id}` === activeVariantId
            )
          : cartData?.items?.find(
              (item) =>
                item?.item_id === itemData?.id || item?.item_id === itemData?.item_id
            );
      if (itemData?.variants_count > 0) {
        findCartItem = {
          ...findCartItem,
          id: cartItem?.id || cartItem?.item_id,
          amount: cartItem?.amount,
          item_name: cartItem?.item_name,
        };
      }

      if (findCartItem?.quantity > 1) {
        dispatch(
          addProductPersonalizationData({
            personalizationData: findCartItem,
            source: source,
            actionType: 'dec',
          })
        );
      } else {
        handleCart(
          type,
          findCartItem?.personalization_info?.personalizations[0],
          null,
          itemData
        );
      }
    } else {
      handleCart(type);
    }
  };

  const handleSetProductCustomizationCount = useCallback((data) => {
    dispatch(
      setPersonalizedProductCustomizationCount({
        imageCount: data?.product_personalization?.image_count,
        textCount: data?.product_personalization?.text_limit,
        allImageMandatory: data?.product_personalization?.is_image_mandatory,
      })
    );
  }, []);

  const hydrateAdditionalRemarks = () => {
    const value = [...state.additionalRemark];
    if (itemIndexInCart === -1) return;
    if (
      state.activeVariant !== -1 &&
      cartData.items?.variants_selected?.[state.activeVariant]
    )
      cartData?.items?.[itemIndexInCart]?.variants_selected?.[state.activeVariant]
        ?.additional_remark &&
        value.push({
          id: cartData.items?.[itemIndexInCart]?.variants_selected?.[state.activeVariant]
            ?.variant_id,
          remark:
            cartData?.items?.[itemIndexInCart]?.variants_selected?.[state.activeVariant]
              ?.additional_remark || '',
        });
    else
      cartData?.items?.[itemIndexInCart]?.additional_remark &&
        value.push({
          id: cartData?.items?.[itemIndexInCart]?.item_id,
          remark: cartData?.items?.[itemIndexInCart]?.additional_remark || '',
        });
    value !== state.additionalRemark &&
      setState((state) => ({ ...state, additionalRemark: value }));
  };

  const handleSetState = (data) => {
    setState((prevState) => ({ ...prevState, activeVariant: data }));
  };

  const setProductData = (data) => {
    dispatch(setProductDataPersonalised(data));
  };

  function handleSetProductData(data) {
    dispatch(setProductDataPersonalised(data));
  }

  useEffect(() => {
    hydrateAdditionalRemarks();
  }, [cartData]);

  return {
    productData,
    setProductData,
    handleCart,
    onBuyNowClick,
    handleIncreamentClick,
    handleDecreamentClick,
    onPersonalizeProductClick,
    handleSetProductCustomizationCount,
    setState,
    handleSetState,
    handleSetProductData,
  };
}
