/**
 * @author Pradyumna Tomar
 */
import { createContext } from 'react';
import { IUseWidgetDndData, IWidgetDndActions, IWidgetDndHelpers } from './types';
import { WIDGET_DND_INITIAL_STATE } from './WidgetDndContextReducer/WidgetDndContextReducer';
import { INITIAL_WIDGET_DND_CONTEXT_ACTIONS } from './WidgetDndContextReducer/actions';
import { DEFAULT_WIDGET_DND_CONTEXT_HELPERS } from './WidgetDndContextReducer/helpers';

const WIDGET_CONTEXT_DATA_INITIAL_STATE = {
  widgetContextState: WIDGET_DND_INITIAL_STATE,
  widgetsCatalogLoaded: false,
  /** Context State Updaters */
  widgetContextActions: INITIAL_WIDGET_DND_CONTEXT_ACTIONS as IWidgetDndActions,
  /** Helpers */
  widgetContextHelpers: DEFAULT_WIDGET_DND_CONTEXT_HELPERS as IWidgetDndHelpers,
};

export const WidgetDndContextCreator = createContext<IUseWidgetDndData>(
  WIDGET_CONTEXT_DATA_INITIAL_STATE as IUseWidgetDndData
);

import { useContext } from 'react';
import { useWidgetDndData } from './useWidgetDndData';

export const useWidgetDndContextData = () => {
  const data = useContext<IUseWidgetDndData>(WidgetDndContextCreator);
  return data;
};

export const WidgetDndContext = ({ children }) => {
  const data = useWidgetDndData();

  return (
    <WidgetDndContextCreator.Provider value={data}>
      {children}
    </WidgetDndContextCreator.Provider>
  );
};
