import { useMemo } from 'react';
import { useSSRSelector } from 'src/redux';

// This adds a collection in the list if a new collection is added by the merchant from web console
export function useGetIterableItems({
  // old list config set from builder
  builderCollections,
  removeNotVisibleItems = false,
  defaultItemVisibility = true,
  dependency = [],
}) {
  const { latestCollections } = useSSRSelector((state) => ({
    latestCollections: state.catalogReducer.collections,
  }));

  const iterableList = useMemo(() => {
    const list = [];
    const newAddedItems = [];

    if (Array.isArray(builderCollections) && latestCollections?.length) {
      latestCollections.forEach((latestCollectionItem) => {
        const builderRefItemIndex = builderCollections?.findIndex(
          (builderItem) => builderItem.id === latestCollectionItem.id
        );
        const builderItem = builderCollections[builderRefItemIndex] || {};
        const isLatestCollectionItemAlreadyPresent = builderRefItemIndex > -1;
        // Is latest item from backend present in build list, update the data
        if (isLatestCollectionItemAlreadyPresent) {
          list[builderRefItemIndex] = {
            ...builderItem,
            itemCount: latestCollectionItem?.categories?.length,
            data: latestCollectionItem,
          };
        }
        // if not then add it in list
        else {
          newAddedItems.push({
            ...builderItem,
            id: latestCollectionItem.id,
            rank: (latestCollectionItem.rank || 0),
            isVisible: defaultItemVisibility,
            itemCount: latestCollectionItem?.categories?.length,
            data: latestCollectionItem,
          });
        }
      });
    }
    let finalList = list?.concat(newAddedItems);
    if (removeNotVisibleItems) {
      finalList = finalList = list?.filter((item) => item && item.isVisible);
    }
    return finalList;
  }, [...dependency, latestCollections]);

  return { iterableList };
}
