export const ADMIN_WIDGETS = {
  HEADER: { type: 'NewHeader', label: 'Header', name: 'NewHeader' },
  FOOTER: { type: 'NewAdminFooter', label: 'Header', name: 'NewAdminFooter' },
  HERO_BANNER: {
    type: 'NewHeroBanner',
    name: 'NewHeroBanner',
    label: 'Hero Banner',
  },
  RICH_TEXT_WITH_IMAGE: {
    type: 'RichTextWithImage',
    name: 'RichTextWithImage',
    label: 'Rich text with image',
  },
  SLIDESHOW: {
    type: 'Slideshow',
    name: 'Slideshow',
    label: 'Slideshow',
  },
  RICH_TEXT: {
    type: 'RichText',
    name: 'RichText',
    label: 'Rich text',
  },
  COLLECTIONS: {
    type: 'Collections',
    name: 'Collections',
    label: 'Collections',
  },
};
