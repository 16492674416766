import { getTotalCartAmounts } from './getTotalCartAmounts';
import { toastNotifyInfo } from '@/components/Common/Toast';

/**
 * Helper function for addition of the variant product
 * item to the cart for update the cart
 * @param {string} type
 * @param {object} item
 * @param {object} variant
 * @param {Array} cart
 * @return {Array}
 */
export const mutateVariantItemWithCartData = (
  type,
  item,
  variant,
  cart,
  personalizedProductData = null,
  ppIdx = null,
  personalizationLocalState = null
) => {
  try {
    const tempCart = JSON.parse(JSON.stringify(cart.items));
    let idx;
    // create important keys in the Item
    item = {
      ...item,
      item_name: item.name,
      quantity: item.quantity ? item.quantity : 1,
      actual_price: item.price,
      discounted_price: item.discounted_price,
      item_id: item.id || item.item_id,
      item_type: 'catalog',
    };

    const personalization_info = {};

    switch (type) {
      // case for increase in item quantity
      case 'inc':
        {
          idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
          if (personalizedProductData) {
            const personalizations = [
              {
                ...personalizedProductData,
                quantity: personalizedProductData?.quantity,
              },
            ];
            personalization_info['personalizations'] = [...personalizations];
          }

          // if variant data and variant selected
          if (tempCart[idx] && tempCart[idx].variants_selected) {
            const variantIdx = tempCart[idx].variants_selected.findIndex(
              (el) => el.variant_id === variant.variant_id
            );
            // if managed inventory is acvtivated and,
            // variant quantity is same as available quantity,
            // disallow addition
            if (
              variantIdx !== -1 &&
              tempCart[idx].variants_selected[variantIdx].managed_inventory &&
              tempCart[idx].variants_selected[variantIdx].quantity ===
                tempCart[idx].variants_selected[variantIdx].available_quantity
            ) {
              toastNotifyInfo('No more units available for purchase');
            } else {
              if (tempCart[idx]?.variants_selected?.length > 0 && variantIdx !== -1) {
                // Increase the variant quantity by one
                tempCart[idx].variants_selected[variantIdx].quantity =
                  tempCart[idx].variants_selected?.[variantIdx]?.quantity + 1;
                tempCart[idx].variants_selected[variantIdx].amount +=
                  variant?.discounted_price;
                if (personalizedProductData) {
                  if (!!personalizedProductData?.quantity) {
                    tempCart[idx].variants_selected[
                      variantIdx
                    ].personalization_info.personalizations.push({
                      ...personalizedProductData,
                    });
                  }
                  if (ppIdx !== undefined && ppIdx !== null) {
                    const personalizations =
                      tempCart[idx].variants_selected[variantIdx].personalization_info
                        .personalizations;
                    personalizations[ppIdx].quantity += 1;
                  }
                }
              } else {
                // add new variant to the selected variant list
                tempCart[idx].variants_selected.push({
                  ...variant,
                  quantity: 1,
                  amount: variant.discounted_price,
                  personalization_info: personalizedProductData
                    ? personalization_info
                    : null,
                });
              }
              /** Increase the whole item quantity by one */
              tempCart[idx].quantity = tempCart[idx].quantity + 1;
              // calculate the new amount
              tempCart[idx].amount = tempCart[idx].variants_selected.reduce(
                (acc, curr) => acc + curr.amount,
                0
              );
            }
          } else {
            item.variants_selected = [
              {
                ...variant,
                quantity: 1,
                amount: variant.discounted_price,
                personalization_info: personalizedProductData
                  ? personalization_info
                  : null,
              },
            ];
            item.amount = variant.discounted_price;
            tempCart.push(item);
          }
        }
        break;
      case 'dec':
        {
          idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
          if (tempCart[idx].variants_selected) {
            const variantIdx = tempCart[idx].variants_selected.findIndex(
              (el) => el.variant_id === variant.variant_id
            );
            if (tempCart[idx].variants_selected[variantIdx].quantity === 1) {
              // Remove the variant if its a last one
              tempCart[idx].variants_selected.splice(variantIdx, 1);
              if (tempCart[idx].variants_selected.length === 0) {
                tempCart.splice(idx, 1);
              } else {
                tempCart[idx].quantity = tempCart[idx].quantity - 1;
                tempCart[idx].amount -= variant.discounted_price;
              }
            } else {
              // Decrease the variant quantity by one
              tempCart[idx].variants_selected[variantIdx].quantity =
                tempCart[idx].variants_selected[variantIdx].quantity - 1;
              tempCart[idx].variants_selected[variantIdx].amount -=
                variant.discounted_price;
              tempCart[idx].quantity = tempCart[idx].quantity - 1;
              tempCart[idx].amount -=
                tempCart[idx].variants_selected[variantIdx].discounted_price;
              if (personalizedProductData && ppIdx !== undefined) {
                const personalizations =
                  tempCart[idx].variants_selected[variantIdx].personalization_info
                    .personalizations;

                if (personalizations && personalizations[ppIdx]) {
                  if (personalizations[ppIdx].quantity === 1) {
                    personalizations.splice(ppIdx, 1);
                  } else {
                    personalizations[ppIdx].quantity -= 1;
                  }
                }
              }
            }
          }
        }
        break;
      case 'repeat':
        {
          idx = tempCart?.findIndex((ele) => ele?.item_id === item?.item_id);

          if (
            tempCart?.[idx]?.variants_selected &&
            tempCart?.[idx]?.variants_selected?.length > 0
          ) {
            tempCart[idx].variants_selected[
              tempCart[idx].variants_selected.length - 1
            ].quantity =
              tempCart?.[idx]?.variants_selected?.[
                tempCart[idx].variants_selected.length - 1
              ]?.quantity + 1;
            tempCart[idx].quantity = tempCart[idx].quantity + 1;
            tempCart[idx].variants_selected[
              tempCart[idx].variants_selected.length - 1
            ].amount += variant.discounted_price;
            // add this new variant price the overall item amount
            tempCart[idx].amount = tempCart[idx].variants_selected.reduce(
              (acc, curr) => acc + curr.discounted_price * curr.quantity,
              0
            );
          }
        }
        break;
      case 'save': {
        idx = tempCart?.findIndex((ele) => ele?.item_id === item?.item_id);
        const variantIdx = tempCart?.[idx]?.variants_selected?.findIndex(
          (el) => el?.variant_id === variant?.variant_id
        );
        if (personalizedProductData) {
          tempCart[idx].variants_selected[variantIdx].personalization_info[
            'personalizations'
          ][ppIdx] = {
            ...personalizedProductData,
          };
        }
      }
      case 'confirm':
        {
          const idx = tempCart?.findIndex(
            (ele) => ele?.item_id === (item?.item_id || item?.id)
          );
          if (idx === -1) return;

          const variantIdx = tempCart?.[idx]?.variants_selected?.findIndex(
            (el) => el?.variant_id === variant?.variant_id
          );
          if (variantIdx === -1) return;
          const selectedVariant = tempCart?.[idx]?.variants_selected?.[variantIdx];

          if (personalizationLocalState) {
            const finalPersonalizedData = personalizationLocalState?.filter(
              (ele) => ele?.quantity !== 0
            );

            if (finalPersonalizedData?.length > 0) {
              selectedVariant.personalization_info = {
                personalizations: finalPersonalizedData,
              };

              const variantItemQty = finalPersonalizedData?.reduce(
                (total, personalization) => total + personalization?.quantity,
                0
              );

              selectedVariant.quantity = variantItemQty;
              selectedVariant.amount = variantItemQty * variant?.discounted_price;
            } else {
              tempCart[idx].variants_selected.splice(variantIdx, 1);
            }

            updateCartItemQuantitiesAndAmounts(tempCart[idx]);

            if (tempCart[idx].variants_selected.length === 0) {
              tempCart.splice(idx, 1);
            }
          }
        }
        break;
      default:
        break;
    }

    const amount_values = getTotalCartAmounts(tempCart);

    return {
      ...cart,
      items: tempCart,
      ...amount_values,
    };
  } catch (err) {
    console.log(err);
    return cart;
  }
};

function updateCartItemQuantitiesAndAmounts(cartItem) {
  cartItem.quantity = cartItem.variants_selected.reduce(
    (total, variantData) => total + variantData.quantity,
    0
  );
  cartItem.amount = cartItem.quantity * cartItem.discounted_price;
}
