/**
 * check if givern object is empty or not
 * @param {object} obj
 * @return {boolean} if object is empty or not
 */

export const checkIfObjectExists = (obj = {}) => {
  if (!obj) return false;
  return obj && Object.keys(obj).length > 0;
};
