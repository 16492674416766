import { getThemeColor } from 'src/utils/getThemeColor';
import { PromosContainer } from '../../Common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { getPromosByTypeAndTypeId } from 'src/redux/actions';
import SliderArrowForward from 'src/assets/svgExports/SliderArrowForward';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const AllPromos = ({ productId, type, openPromoModal, customClasses = '' }) => {
  const dispatch = useDispatch();

  const themeColor = getThemeColor();
  const [showAllPromos, setShowAllPromos] = useState(false);
  const [promos, setPromos] = useState([]);

  const { storeId, userId } = useSelector((state: RootState) => ({
    storeId: state.storeReducer.store.store_id,
    userId: state.userReducer.data?.user_id || 0,
  }));

  useEffect(() => {
    if (productId) {
      getProductAssociatedPromos();
    }
  }, [productId]);

  function getProductAssociatedPromos() {
    const data = {
      type: type,
      type_ids: [productId],
      store_id: storeId,
      user_id: userId,
    };
    dispatch(getPromosByTypeAndTypeId(data, (data) => setPromos(data)));
  }

  return (
    !!promos?.length && (
      <div
        className={`tw-flex tw-w-full tw-flex-col tw-gap-[12px] tw-pt-[24px] md:tw-gap-[16px] md:tw-border-b md:tw-border-solid md:tw-border-[#E9E9E9] ${customClasses}`}
      >
        <p className="tw-m-0 tw-pl-[16px] tw-text-[16px]/[20px] tw-font-semibold md:tw-pl-0">
          Offers
        </p>
        <PromosContainer
          promos={showAllPromos ? promos : promos.slice(0, 3)}
          onPromoClick={openPromoModal}
          primaryTextClasses="tw-text-[13px]/[18px] tw-font-medium md:tw-text-[14px]/[20px] md:tw-font-semibold"
          secondaryTextClasses="tw-text-[12px]/[20px] tw-font-normal md:tw-text-[13px]/[20px]"
          offerStripContainerClasses={`!tw-flex-row tw-justify-between tw-items-center md:tw-items-start ${IS_DESKTOP ? 'tw-px-0' : ''}`}
          offerStripCtaTextOrIcon={
            !IS_DESKTOP && <SliderArrowForward color="#000000" height={10} width={6} />
          }
        />
        {promos?.length > 3 && (
          <p
            className="tw-m-0 tw-cursor-pointer tw-pb-[20px] tw-pr-[16px] tw-pt-[8px] tw-text-right tw-text-[16px]/[20px] tw-font-semibold md:tw-pb-[10px] md:tw-text-left md:tw-text-[18px]/[20px]"
            style={{ color: themeColor }}
            onClick={() => setShowAllPromos(!showAllPromos)}
          >
            View {showAllPromos ? 'less' : 'all'} offers
          </p>
        )}
        {IS_DESKTOP && (
          <p className="tw-m-0 tw-pb-[10px] tw-text-[15px]/[20px]">
            *Coupons can be applied at checkout
          </p>
        )}
      </div>
    )
  );
};

export default AllPromos;
