import OfferAvailableTag from '../CouponsAndVouchers/OfferAvailableTag';
import styles from './index.module.scss';

const setBackgroundColor = (rating) => {
  if (rating < 2) return '#F54C52';
  if (rating < 3) return '#FF9F00';
  if (rating < 4) return '#66BB6A';
  if (rating < 5) return '#43A047';
  return '#2E7D32';
};

const schemaTag = (key, value, hideRDFa) => {
  if (hideRDFa) {
    return {};
  } else {
    return { [key]: value };
  }
};

const RatingBox = ({
  isVisible,
  rating,
  ratingsCount = undefined,
  hideRDFa,
  isNewStyledRatingBox,
  productInfo = null,
  isPdp = false,
}) => {
  return isVisible && rating ? (
    <div {...schemaTag('rel', 'schema:aggregateRating', hideRDFa)}>
      <div
        className={styles.ratingBoxContainer}
        {...schemaTag('typeof', 'schema:AggregateRating', hideRDFa)}
      >
        <div
          className={`${styles.ratingBox} ${
            isNewStyledRatingBox ? styles.updatedRatingBox : ''
          }`}
          style={{ backgroundColor: setBackgroundColor(rating) }}
        >
          <p
            className={`mv0 pr4px ${
              isNewStyledRatingBox ? styles.updatedNoOfRating : ''
            }`}
            {...schemaTag('property', 'schema:ratingValue', hideRDFa)}
          >
            {Number.isInteger(rating) ? rating : rating.toFixed(1)}
          </p>
          <img
            alt="Rating Star"
            src={
              isNewStyledRatingBox
                ? '/assets/images/green-star.svg'
                : '/assets/images/white-star.svg'
            }
            width="12px"
            height="11px"
          />
        </div>
        {ratingsCount && (
          <p className="mv0">
            <span {...schemaTag('property', 'schema:ratingCount', hideRDFa)}>
              {ratingsCount}
            </span>
          </p>
        )}
        <OfferAvailableTag productInfo={productInfo} customClasses="tw-ml-[12px]" />
      </div>
    </div>
  ) : null;
};

export default RatingBox;
