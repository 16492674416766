import {
  PLACE_ORDER,
  SAVE_ORDER,
  FETCH_ORDER_DETAILS_BY_ID,
  SAVE_ORDER_DETAILS,
  FETCH_ORDER_BY_HASH,
  FETCH_ORDER_LIST,
  SAVE_ORDER_LIST_PENDING,
  SAVE_ORDER_LIST_COMPLETE,
  UPDATE_ORDER_STATE,
  GET_GST_INVOICE,
  SAVE_GST_INVOICE,
  FETCH_LOGGED_IN_ORDER_BY_HASH,
  SAVE_PARENT_ORDER_DETAILS,
  SET_ORDER_STATUS_ERROR_FOR_INVALID_USER,
  CANCEL_ORDER,
  SAVE_ORDER_LIST_CONFIG,
  GET_STORE_ORDER,
} from '../actionTypes';

export const placeOrder = (data, router, callback) => {
  return {
    type: PLACE_ORDER,
    data,
    router,
    callback,
  };
};

export const saveOrder = (data) => {
  return {
    type: SAVE_ORDER,
    data,
  };
};

export const fetchOrderDetailByID = (data, router) => {
  return {
    type: FETCH_ORDER_DETAILS_BY_ID,
    data,
    router,
  };
};

export const saveOrderDetails = (data) => {
  return {
    type: SAVE_ORDER_DETAILS,
    data,
  };
};

export const fetchStoreOrder = (hash, callback) => {
  return {
    type: GET_STORE_ORDER,
    data: {
      hash,
      callback,
    },
  };
};

// export function emptyOrderDetails(data) {
//   return {
//     type: EMPTY_ORDER_DETAILS,
//     data,
//   };
// }

export const fetchOrderByHash = (data) => {
  return {
    type: FETCH_ORDER_BY_HASH,
    data,
  };
};

export const fetchLoggedInOrderByHash = (data, callback) => {
  return {
    type: FETCH_LOGGED_IN_ORDER_BY_HASH,
    data,
    callback,
  };
};

// export function saveOrderHash(data) {
//   return {
//     type: SAVE_ORDER_HASH,
//     data,
//   };
// }

export const fetchOrderList = (data, callback) => {
  return {
    type: FETCH_ORDER_LIST,
    data,
    callback,
  };
};

export const saveOrderListPending = (data, replace = false) => {
  return {
    type: SAVE_ORDER_LIST_PENDING,
    data,
    replace,
  };
};

export const saveOrderListConfig = (data) => {
  return {
    type: SAVE_ORDER_LIST_CONFIG,
    data,
  };
};

export const saveOrderListComplete = (data, replace = false) => {
  return {
    type: SAVE_ORDER_LIST_COMPLETE,
    data,
    replace,
  };
};

// export function showOrderLoader(data) {
//   return {
//     type: SHOW_ORDER_LOADER,
//     data,
//   };
// }

export const updateOrderState = (data, router) => {
  return {
    type: UPDATE_ORDER_STATE,
    data,
    router,
  };
};

export const getGstInvoice = () => {
  return {
    type: GET_GST_INVOICE,
  };
};

export const saveGstInvoice = (data) => {
  return {
    type: SAVE_GST_INVOICE,
    data,
  };
};

export const saveParentOrderDetails = (data) => {
  return {
    type: SAVE_PARENT_ORDER_DETAILS,
    data,
  };
};

export const setOrderErrorForInvalidUser = (data) => {
  return {
    type: SET_ORDER_STATUS_ERROR_FOR_INVALID_USER,
    data,
  };
};

export function cancelOrder(data) {
  return {
    type: CANCEL_ORDER,
    data,
  };
}
