import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line import/named
import Sortable, { SortableEvent } from 'sortablejs';
import { getRegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { PAGE_BUILDER_COLUMN_CONFIG } from './widgetDnDConstants';
import styles from './widgetDnD.module.scss';
import { withErrorBoundary } from '../WidgetDeveloper/WidgetPreview/ErrorBoundryPreview';
import { WidgetJSONData } from './widgetDnDUtils';
import cn from 'classnames';
import postcss from 'postcss';
import modifySelectors from 'modify-selectors';
import { loadReactComponent, loadWidgetStyles } from '../widgetLoader/moduleLoader';
import { useConvertStoreToWidgetData } from '../widgetMakerUtils';
import { useSSRSelector } from '@/redux/ssrStore';
export interface RenderWidgetProps {
  widget: WidgetJSONData;
  mode?: 'view' | 'edit';
  onAdd?: (e: SortableEvent) => void;
}

const getPostcssParentModifier = (parentSelector: string) => {
  return postcss([
    modifySelectors({
      enable: true,
      prefix: [{ match: '*', with: parentSelector }],
    }),
  ]);
};

export const scopeStyles = (cssCode: string, parentSelector: string) => {
  return getPostcssParentModifier(parentSelector)
    .process(cssCode)
    .then((result) => result.css);
};

// eslint-disable-next-line
export const RenderWidgetWrapper: FunctionComponent<
  PropsWithChildren<RenderWidgetProps>
> = (props) => {
  const childRef = useRef<HTMLDivElement>();

  const { widget } = props;

  const { openInNewTab, link, mobileHidden, desktopHidden, ...containerProps } =
    widget.props.container || {};

  const [templateWidget, setTemplateWidget] = useState<{
    style?: string;
    component?: React.ComponentType<any>;
  }>({});

  const { widgetTemplateLibraryMap } = useSSRSelector((state) => ({
    widgetTemplateLibraryMap: state.jsonWidgetsReducer.widgetTemplateLibraryMap,
  }));

  const mode = props.mode || 'view';

  // look up -> in-codebase theme widgets or external template widgets (temporary changes done in getWidgetSSRData line 29)
  const widgetData =
    getRegisteredWidget(widget.name) || widgetTemplateLibraryMap[widget.name];

  // const widgetsTemplateConfigs = useSSRSelector(
  //   (state) => state.jsonWidgetsReducer.widgetsTemplateConfigs
  // );

  const store = useConvertStoreToWidgetData();

  useEffect(() => {
    if (childRef.current && props.onAdd) {
      // new Sortable(childRef.current, { ...PAGE_BUILDER_COLUMN_CONFIG, onAdd: props.onAdd });
      const sortableContainers =
        childRef.current.querySelectorAll<HTMLDivElement>(`[data-sortable]`);
      for (const container of Array.from(sortableContainers)) {
        const hasSortable = Object.keys(container).some((key) =>
          key.startsWith('Sortable')
        );
        if (!hasSortable) {
          new Sortable(container, { ...PAGE_BUILDER_COLUMN_CONFIG, onAdd: props.onAdd });
        }
      }
    }
  }, [childRef.current]);

  useEffect(() => {
    if (widgetData?.type === 'template') {
      const { name } = widget.props;
      const stylePromise = loadWidgetStyles(name);

      const widgetPromise = loadReactComponent(name);
      const widgetClass = `widget-${name}`;
      Promise.all([stylePromise, widgetPromise]).then(async ([style, widget]) => {
        const scopedStyles = await scopeStyles(style, `.${widgetClass}`);
        setTemplateWidget({ style: scopedStyles, component: widget });
      });
    }
  }, [widget.props?.name]);

  let component = <></>;

  const classNames = cn(styles.widgetContainer, 'widgetContainer', {
    'hide-mobile': mobileHidden,
    'hide-desktop': desktopHidden,
  });

  if (widgetData?.type === 'template' && templateWidget.component) {
    const Widget = withErrorBoundary(templateWidget.component);
    component = (
      <div className={classNames} style={{ ...containerProps }} ref={childRef}>
        <style>{templateWidget.style}</style>
        <Widget widgetOptions={widget.props} {...widget.props} {...store} />
      </div>
    );
  }

  if (widgetData?.type === 'kui') {
    const Component = withErrorBoundary(widgetData.component);
    // const fallbackProps = { children: <span className="placeholder">Placeholder for {widgetData.name}</span> }
    const children = props.children; //  || fallbackProps.children
    component = (
      <div className={classNames} style={{ ...containerProps }} ref={childRef}>
        <Component mode={mode} {...widget.props} widget={widget} onAdd={props.onAdd}>
          {children}
        </Component>
      </div>
    );
  }

  if (widgetData?.type === 'theme') {
    // const Component = withErrorBoundary(widgetData.component);
    const Component = widgetData.component;
    component = (
      <div className={classNames} style={{ ...containerProps }} ref={childRef}>
        <Component
          widgetOptions={widget.props}
          mode={mode}
          {...widget.props}
          {...store}
        />
      </div>
    );
  }

  if (link) {
    const newTabProps = openInNewTab
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : {};
    component = (
      <a
        href={link}
        {...newTabProps}
        className={classNames}
        style={{ ...containerProps }}
      >
        {component}
      </a>
    );
  }

  return component;
};

export const RenderWidget = React.memo(RenderWidgetWrapper, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.widget) === JSON.stringify(nextProps.widget);
});

// export const RenderWidget = RenderWidgetWrapper;
