import { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styles from './index.module.scss';
import moment from 'moment';
import RatingBox from '../RatingBox';

const CustomerReviewReply = ({ reviewRatings, review, index }) => {
  const [showCompleteReviewAndReply, setShowCompleteReviewAndReply] = useState({
    review: false,
    reply: false,
  });
  const { userData } = useSelector(state => ({
    userData: state.userReducer,
  }));

  return (
    <div rel="schema:review">
      <div
        className={styles.customerReviewReplyContainer}
        style={{
          borderBottom:
            index !== reviewRatings?.length - 1 ? '1px solid #E2E2E2' : '0px',
        }}
        typeof="schema:Review"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {review?.rating && (
              <span property="reviewRating" typeof="Rating">
                <meta property="ratingValue" content={review?.rating} />
              </span>
            )}
            <RatingBox
              isVisible={!!review?.rating}
              rating={review?.rating}
              hideRDFa
            />
            <p
              className="mv0 sonic-silver pl8px pr4px"
              property="author"
              typeof="Person"
            >
              <span property="name">{review?.full_name}</span>
            </p>
            {review?.verified_buyer ? (
              <>
                <img src="/assets/images/green-circular-tick.png" />
                <p className="mv0 pl4px fw5 c24AC21">Genuine Buyer</p>
              </>
            ) : null}
            <p
              className="mv0 pl6px sonic-silver"
              property="datePublished"
              content={moment(review?.review_date).format('YYYY-MM-DD')}
            >
              {` | ${moment(review?.review_date).format('DD MMMM YYYY')}`}
            </p>
          </div>
          {userData?.isLogin &&
          review.phone === userData?.data?.phone &&
          !review.status ? (
            <>
              <img
                src="/assets/images/clock-timer.svg"
                className="pointer"
                data-tip
                data-for="review-under-process"
              />
              <ReactTooltip
                id="review-under-process"
                place="bottom"
                effect="solid"
                multiline={true}
                padding="8px"
              >
                <p className="mv0 fw5 f12px">Review under process</p>
              </ReactTooltip>
            </>
          ) : null}
        </div>
        <div className="pt12px">
          <span className="mv0 pt14px f14px fw4 c28292C" property="reviewBody">
            {showCompleteReviewAndReply.review
              ? review?.review
              : review?.review?.substr(0, 219)}
          </span>
          {(review?.review).length > 219 ? (
            showCompleteReviewAndReply.review ? (
              <span
                className={styles.readMore}
                onClick={() =>
                  setShowCompleteReviewAndReply(prevState => ({
                    ...prevState,
                    review: false,
                  }))
                }
              >
                ... Read Less
              </span>
            ) : (
              <span
                className={styles.readMore}
                onClick={() =>
                  setShowCompleteReviewAndReply(prevState => ({
                    ...prevState,
                    review: true,
                  }))
                }
              >
                ... Read More
              </span>
            )
          ) : (
            ''
          )}
        </div>
        {review?.reply ? (
          <div className={styles.replyContainer}>
            <p className="mv0">
              <span>Seller's Reply</span> |{' '}
              {moment(review?.reply_date).format('DD MMMM YYYY')}
            </p>
            <span className="mv0 pt14px f14px fw4 c28292C">
              {showCompleteReviewAndReply.reply
                ? review?.reply
                : review?.reply?.substr(0, 219)}
            </span>
            {(review?.reply).length > 219 ? (
              showCompleteReviewAndReply.reply ? (
                <span
                  className="c28292C f14px fw5 pointer"
                  onClick={() =>
                    setShowCompleteReviewAndReply(prevState => ({
                      ...prevState,
                      reply: false,
                    }))
                  }
                >
                  ... Read Less
                </span>
              ) : (
                <span
                  className="c28292C f14px fw5 pointer"
                  onClick={() =>
                    setShowCompleteReviewAndReply(prevState => ({
                      ...prevState,
                      reply: true,
                    }))
                  }
                >
                  ... Read More
                </span>
              )
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerReviewReply;
