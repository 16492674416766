export const JSONPAGEWIDGETPAYLOAD = {
  type: 18,
  component_type: 'Body',
  id: '6569717b7610995c558a55bb',
  template: JSON.stringify({}),
  ordering: 0,
  status: 1,
  is_editable: true,
  name: 'JsonPageWidget',
  widget_category: 2,
};

export const FLOATING_BUTTON = {
  id: '6563159e679a8b29dbd3220f',
  type: 26,
  component_type: 'Body',
  config: {
    scrollToTop: {
      config: {
        '97SCROLL47f9c22c50687bb2bb892': {
          config: {
            isVisible: true,
            actions: {
              handler: '',
              newTab: false,
              type: 3,
            },
            additionalInfo: null,
            btnText: '',
            isEditable: true,
          },
          id: '97SCROLL47f9c22c50687bb2bb892',
          link: '',
          name: 'ScrollToTop1',
          styleConfig: {
            alignment: 'right',
          },
          type: 'ScrollToTop',
        },
      },
      isEnabled: false,
    },
    enquiryForm: {
      config: {
        '97ENQUIRY47f9c22c50687bb2bb891': {
          config: {
            actions: {
              handler: '',
              newTab: true,
              type: 2,
            },
            additionalInfo: null,
            btnText: 'Enquire Now ',
            isEditable: true,
            isVisible: true,
          },
          id: '97ENQUIRY47f9c22c50687bb2bb891',
          link: '',
          name: 'EnquiryForm1',
          styleConfig: {
            alignment: 'right',
          },
          type: 'EnquiryForm',
        },
      },
      formId: '',
      isEnabled: true,
    },
  },
  ordering: 0,
  status: 1,
  is_editable: true,
  name: 'FloatingButton1',
  widget_category: 2,
};

export const THEME_BUILDER_FLOW_TYPE = {
  CREATE: 1,
  UPDATE: 2,
};

export const WIDGET_TYPE = {
  HEADER: 'Header',
  FOOTER: 'Footer',
  MODERN_HEADER: 'ModernHeader',
  MODERN_FOOTER: 'ModernFooter',
} as const;

export const WIDGET_TYPES = {
  TEMPLATE: 'template',
  THEME: 'theme',
  KUI: 'kui',
};

export const ECOMM_HEADERS = [
  'Header1',
  'Header2',
  'Header3',
  'Header4',
  'Header5',
  'Header6',
  'Header7',
  'Header8',
  'Header9',
  'Header10',
  'Header11',
  'Header12',
  'Header13',
  'Header14',
];

export const ECOMM_FOOTERS = ['Footer', 'Footer1', 'Footer2', 'Footer3', 'Footer4'];

export const HEADER_FOOTER_VARIANTS = {
  HEADERS: ECOMM_HEADERS.map((name) => ({
    title: name,
    name,
    imgUrl: `/assets/images/widgetImages/Headers/${name.toLowerCase()}.png`,
    propsConfig: { name },
  })),
  FOOTERS: ECOMM_FOOTERS.map((name) => ({
    title: name,
    name,
    imgUrl: `/assets/images/widgetImages/Footers/${name.toLowerCase()}.png`,
    propsConfig: { name },
  })),
};

export const HEADER_CONFIGURABLES = {
  NAME: 'headerName',
  BACKGROUND_COLOR: 'headerBgColor',
  LOGIN: 'loginView',
  WHATSAPP: 'whatsappView',
  SHOP_DROPDOWN: 'isShopDropDownEnable',
  STORE_LOGO: 'storeLogoVisible',
};

export const BANNER_BUTTON_TYPES = {
  solid: { text: 'Solid', value: 'solid' },
  outline: { text: 'Outline', value: 'outline' },
  textual: { text: 'Textual', value: 'textual' },
};

export const TEXT_ALIGNMENT_POSITIONS = {
  right: 'right',
  left: 'left',
  center: 'center',
};

export const FLEX_ALIGNMENT_CLASS = {
  right: 'justify-end',
  center: 'justify-center',
  left: 'justify-start',
};

export const GRID_ALIGNMENT_CLASS = {
  top_right: 'tw-top-0 tw-right-0 tw-left-1/2',
  top_center: 'tw-top-0 tw--translate-x-1/2 tw-left-1/2',
  top_left: 'tw-top-0',
  mid_right: 'tw-right-0 tw-top-1/2 tw--translate-y-1/2',
  mid_center: 'tw--translate-y-1/2 tw-top-1/2 tw--translate-x-1/2 tw-left-1/2',
  mid_left: 'tw-left-0 tw-top-1/2 tw--translate-y-1/2 tw-left-1/2',
  bottom_right: 'tw-right-0 tw-bottom-0',
  bottom_center: 'tw-left-1/2 tw-bottom-0 tw--translate-x-1/2',
  bottom_left: 'tw-left-0 tw-bottom-0',
  top: 'tw-top-0',
  middle: 'tw-top-1/2 tw--translate-y-1/2',
  bottom: 'tw-bottom-0',
};

export const MOBILE_DEVICE = 'mobile';
export const DESKTOP_DEVICE = 'dekstop';

export const WIDGET_TAB_SELECTION = [
  {
    name: 'Sections',
    value: 'section',
    spriteDetail: 'wb-sprite ic-color-icon',
    selectedSprite: 'wb-sprite ic-color-icon-active',
  },
  {
    name: 'Site styles',
    value: 'globalStyle',
    spriteDetail: 'wb-sprite ic-color-icon',
    selectedSprite: 'wb-sprite ic-color-icon-active',
  },
];

export const MULTI_TAB_VALUE = {
  SECTION: 'section',
  GLOBAL_STYLE: 'globalStyle',
};
