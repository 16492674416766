import React from 'react';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import { twMerge } from 'tailwind-merge';
import { getButtonVariantsData } from '../../Carousel/utils';
import { DownArrowIcon } from '../../CardFrameCollection/components/svg';
import { BUTTON_TYPE } from '@/components/WidgetMaker/utils/buttonConstant';

export default function ViewMoreButton({
  listPresentationConfig,
  viewAllItemsConfig,
  setViewAllItemsConfig,
  cardFrameConfig,
}) {
  if (!viewAllItemsConfig?.showButton) {
    return null;
  }
  return listPresentationConfig?.viewAllItemsButtonConfig.map((buttonData, btnIndex) => {
    const buttonVariant = getButtonVariantsData(buttonData);
    const selectedButtonType = buttonVariant[buttonData.type];
    return buttonData.isShow ? (
      <CustomizedButton
        key={btnIndex}
        size={cardFrameConfig?.contentConfig?.textConfig?.textSize}
        btnType={buttonData.type}
        buttonData={buttonData}
        selectedButtonData={selectedButtonType}
        variant={selectedButtonType.variant}
        imageDirection="right"
        image={
          <DownArrowIcon
            arrowColor={buttonData?.btnStyleConfig?.textColor}
            height="18px"
            width="18px"
            className={twMerge(
              'tw-relative',
              viewAllItemsConfig?.isViewAllVisible ? 'tw-rotate-180' : ''
            )}
          />
        }
        onClick={() =>
          setViewAllItemsConfig((data) => ({
            ...data,
            isViewAllVisible: !data.isViewAllVisible,
          }))
        }
        className={twMerge(
          'tw-pointer-events-auto !tw-mt-[36px] !tw-max-w-[100%] !tw-whitespace-nowrap !tw-px-[24px] !tw-py-[12px] !tw-text-[14px] !tw-font-normal !tw-leading-[20px]',
          !buttonData?.buttonAnimationConfig?.classKey
            ? '[&_p]:before:!tw-hidden'
            : buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey],
          buttonData.type === BUTTON_TYPE.SOLID ? ' !tw-border-none ' : ''
        )}
      >
        {!viewAllItemsConfig?.isViewAllVisible ? buttonData?.text : 'Show less'}
      </CustomizedButton>
    ) : null;
  });
}
