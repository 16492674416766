import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import CardFrameForCollection from '../CardFrameCollection/CardFrameCollection';
import CarouselIndicator from '../CardFrameCollection/components/CarouselIndicator';
import {
  getArrowMethods,
  getContainerTracks,
  getGridGapsForAxes,
  getOneCellGeometry,
  INITIAL_SCROLL_PERCENTAGE,
  MAX_SECTION_WIDTH,
  onCarouselScroll,
} from './utils';
import { useGetIterableItems } from './hooks/useGetIterableItems';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { ListDescriptionSection } from './components/ListDescriptionSection';
import ViewMoreButton from './components/ViewMoreButton';
import QuickViewModal from '../../QuickViewModal';
import { useGetElementRects } from './hooks/useGetElementRects';

export default function ListPresentation(props) {
  const isBuilder = !IS_SERVER ? location.pathname?.includes('dnd') : false;

  const listPresentationConfig = props?.widgetOptions?.listPresentation;
  const cardFrameConfig = props?.widgetOptions?.cardFrame;
  const {
    widgetContextState: { previewDevice },
  } = useWidgetDndContextData();

  const { deviceType } = useGetDeviceType();

  const [carouselIndicatorConfigs, setCarouselIndicatorConfigs] = useState({
    scrollPercentage: INITIAL_SCROLL_PERCENTAGE,
    overflowSectionCount: 0,
    currentOverflowSectionIndex: 0,
    direction: 'to-right',
  });
  const [viewAllItemsConfig, setViewAllItemsConfig] = useState({
    showButton: false,
    isViewAllVisible: false,
  });

  const sectionContainerRef = useRef(null);
  const itemsContainerRef = useRef(null);

  const isMobile = isBuilder ? previewDevice === 'mobile' : deviceType === 'mobile';
  const isLayoutCarousel = listPresentationConfig?.layoutType === 'carousel';

  // Paddings & margin calculations
  const containerHorizontalPadding = !isMobile ? 40 : !isLayoutCarousel ? 20 : 0;
  const contentHorizontalPadding = !containerHorizontalPadding
    ? !isMobile
      ? 40
      : 20
    : 0;
  const listHorizontalPadding = isLayoutCarousel && isMobile ? 20 : 0;
  const containerVerticalPadding =
    listPresentationConfig?.[!isMobile ? 'paddingY' : 'mobilePaddingY'];
  const containerTopMargin = listPresentationConfig?.marginTop;
  const containerBottomMargin = listPresentationConfig?.marginBottom;

  // Section Width calculations
  const SECTION_WIDTH = !IS_SERVER
    ? sectionContainerRef.current?.clientWidth - containerHorizontalPadding * 2
    : isMobile
      ? 480
      : 720;

  // Item gaps
  const gridGapAxis = getGridGapsForAxes({ isMobile, cardFrameConfig });
  const aspectRatio = cardFrameConfig?.aspectRatio || '1:1';
  const sectionColors =
    listPresentationConfig?.colors?.colorSchemes?.[
      listPresentationConfig?.colors?.selectedColorSchemeIndex
    ];
  const showArrow = isLayoutCarousel
    ? isMobile
      ? listPresentationConfig?.showArrowInMobile
      : true
    : false;
  const slideOffsetMultiplier = {
    forWidth: isMobile ? 1 : 2,
    forGap: isMobile ? 1 : 2,
  };

  const { iterableList } = useGetIterableItems({
    builderCollections: listPresentationConfig?.contentItems?.listItem,
    removeNotVisibleItems: true,
    dependency: [listPresentationConfig?.contentItems],
  });
  const itemCountTotal = iterableList?.length;

  // Returns number of columns & rows
  const containerTracks = getContainerTracks({
    isLayoutCarousel,
    itemCount: itemCountTotal,
    isMobile,
    configData: listPresentationConfig,
  });
  const numItemsToShow = containerTracks.numColumns * containerTracks.numRows;

  const [itemContainerLayoutChange] = useGetElementRects({
    elementRef: itemsContainerRef,
  });

  useEffect(() => {
    if (itemsContainerRef.current && iterableList?.length) {
      setTimeout(() => {
        const { clientWidth, scrollWidth } = itemsContainerRef.current || {};

        const sectionScrollWidth = Math.ceil(
          (iterableList?.length - containerTracks.numColumns) /
            slideOffsetMultiplier.forWidth +
            (!isMobile ? 1 : 0)
        );

        if (scrollWidth > clientWidth) {
          setCarouselIndicatorConfigs((data) => ({
            ...data,
            overflowSectionCount: sectionScrollWidth,
            // overflowSectionCount: Math.ceil(scrollWidth / clientWidth),
            currentOverflowSectionIndex: 1,
          }));
        }
      }, 500);
    }
  }, [
    iterableList,
    isLayoutCarousel,
    isMobile,
    listPresentationConfig?.progressIndicator,
    previewDevice,
    containerTracks.numColumns,
    slideOffsetMultiplier.forWidth,
  ]);

  useEffect(() => {
    if (isLayoutCarousel) {
      setViewAllItemsConfig({ isViewAllVisible: true, showButton: false });
    } else if (numItemsToShow < itemCountTotal) {
      setViewAllItemsConfig({ isViewAllVisible: false, showButton: true });
    } else {
      setViewAllItemsConfig({ isViewAllVisible: true, showButton: false });
    }
  }, [itemCountTotal, isLayoutCarousel, numItemsToShow, previewDevice]);

  const showCarouselIndicators =
    isLayoutCarousel &&
    (itemCountTotal > containerTracks?.numColumns ||
      itemsContainerRef.current?.clientWidth < itemsContainerRef.current?.scrollWidth);

  // Remaining width from section after removing gaps & x-padding
  const cardContainerWidthForEachCell =
    SECTION_WIDTH +
    gridGapAxis.columnGap -
    containerTracks.numColumns * gridGapAxis.columnGap -
    2 * listHorizontalPadding;

  const widthAdditionals =
    isLayoutCarousel && itemCountTotal > containerTracks.numColumns && isMobile
      ? -containerTracks.numColumns * 6
      : 0;

  // Width for each column (product item)
  const oneGridCellGeometry = getOneCellGeometry({
    cardContainerWidthForEachCell,
    containerTracks,
    aspectRatio,
    widthAdditionals,
  });

  const slideOffset =
    oneGridCellGeometry.width * slideOffsetMultiplier.forWidth +
    gridGapAxis.columnGap * slideOffsetMultiplier.forGap;

  const { onNextArrowClick, onPrevArrowClick } = getArrowMethods(
    itemsContainerRef,
    slideOffset
  );

  return (
    <section
      className={classNames(`tw-mx-auto tw-flex tw-w-[100%] tw-flex-col tw-items-center`)}
      ref={sectionContainerRef}
      style={{
        padding: `${containerVerticalPadding}px ${containerHorizontalPadding}px`,
        margin: `${containerTopMargin}px auto ${containerBottomMargin}px auto`,
        background: sectionColors?.background,
        maxWidth: `${MAX_SECTION_WIDTH}px`,
      }}
    >
      <ListDescriptionSection
        listPresentationConfig={listPresentationConfig}
        isMobile={isMobile}
        containerHorizontalPadding={contentHorizontalPadding}
        showArrow={showArrow}
        sectionColors={sectionColors}
        carouselIndicatorConfigs={carouselIndicatorConfigs}
        onNextArrowClick={onNextArrowClick}
        onPrevArrowClick={onPrevArrowClick}
      />
      <div
        className={classNames(
          `no-scrollbar tw-mx-auto tw-max-w-[100%]`,
          !isLayoutCarousel ? `tw-grid` : `tw-flex`
        )}
        style={{
          ...(!isLayoutCarousel
            ? {
                gridTemplateColumns: `repeat(${viewAllItemsConfig?.isViewAllVisible ? 'auto-fit' : containerTracks?.numColumns}, ${oneGridCellGeometry.width}px)`,
                ...(!viewAllItemsConfig?.isViewAllVisible
                  ? {
                      gridTemplateRows: `repeat(${containerTracks?.numRows}, auto)`,
                    }
                  : {}),
                ...gridGapAxis,
              }
            : { gap: gridGapAxis.columnGap }),
          overflowX: isLayoutCarousel ? 'auto' : 'unset',
          padding: `0px ${listHorizontalPadding}px`,
        }}
        ref={itemsContainerRef}
        onScroll={(e) =>
          onCarouselScroll({
            e,
            setCarouselIndicatorConfigs,
            slideOffset,
            widthOffset: widthAdditionals,
          })
        }
      >
        {iterableList
          ?.slice(
            0,
            viewAllItemsConfig?.isViewAllVisible ? itemCountTotal : numItemsToShow
          )
          .map((data, index) => {
            return (
              <CardFrameForCollection
                key={index}
                itemConfig={data}
                itemIndex={index}
                containerTracks={containerTracks}
                cardFrameConfig={cardFrameConfig}
                oneGridCellGeometry={oneGridCellGeometry}
                openInNewTab={listPresentationConfig?.openInNewTab}
                isMobile={isMobile}
              />
            );
          })}
      </div>
      <ViewMoreButton
        listPresentationConfig={listPresentationConfig}
        viewAllItemsConfig={viewAllItemsConfig}
        setViewAllItemsConfig={setViewAllItemsConfig}
        cardFrameConfig={cardFrameConfig}
      />
      <CarouselIndicator
        showArrowUI={showArrow}
        isVisible={showCarouselIndicators}
        config={listPresentationConfig}
        itemContainerLayoutChange={itemContainerLayoutChange}
        carouselIndicatorConfigs={carouselIndicatorConfigs}
        onPrevArrowClick={onPrevArrowClick}
        onNextArrowClick={onNextArrowClick}
        arrowPosition={listPresentationConfig?.arrowPosition}
        isMobile={isMobile}
        containerHorizontalPadding={contentHorizontalPadding}
      />
      <QuickViewModal />
    </section>
  );
}
