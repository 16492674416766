import { useState } from 'react';
import { useSelector } from 'react-redux';
import RatingStar from '../RatingStar';
import CustomerReviewReply from './CustomerReviewReply';
import styles from './index.module.scss';
import CustomerReviewModal from '../CustomerReviewModal';
import VerifiedBuyerModal from './VerifiedBuyerModal';
import { deviceWidth } from '@/utils/deviceWidth';
import ReactTooltip from 'react-tooltip';

const ReviewsSection = ({ product, shouldReviewItem, data }) => {
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [openVerifiedModal, setOpenVerifiedModal] = useState(false);
  const { userData, storeInfo } = useSelector((state) => ({
    userData: state.userReducer,
    storeInfo: state.storeReducer.store,
  }));
  const publishedReviewsAndUserReview = data?.review_rating?.filter((review) => {
    return (
      (review.status && review.review) ||
      (userData?.isLogin && review.review && review.phone === userData?.data?.phone)
    );
  });
  const loggedInUserReview = data?.review_rating?.find(
    (review) => review?.phone === userData?.data?.phone
  );
  const reviewRatings = showAllReviews
    ? publishedReviewsAndUserReview
    : publishedReviewsAndUserReview?.slice(0, 8);

  const handleReviewModalOpen = () => {
    if (!shouldReviewItem() && deviceWidth > 767) return;
    if (!shouldReviewItem() && deviceWidth <= 767) {
      setOpenVerifiedModal(true);
      return;
    }
    setOpenReviewModal(true);
  };

  const toggleShowAllReviews = () => {
    setShowAllReviews((prevState) => !prevState);
  };

  const handleCustomerReviewModalClose = () => {
    setOpenReviewModal(false);
    window.location.reload();
  };

  return (
    <>
      <div id="reviews-section" className={styles.reviewsSectionContainer}>
        <div className="flex items-center justify-between">
          <h2 className="mv0 fw6 f18px">
            {data?.reviews_count ? 'Ratings & Reviews' : 'No Reviews yet'}
          </h2>
          {userData?.isLogin ? (
            <>
              <button
                style={{
                  opacity: shouldReviewItem() ? 1 : 0.21,
                  border: `1px solid ${
                    storeInfo?.theme?.colors?.primary_color
                      ? storeInfo?.theme?.colors?.primary_color
                      : '#5c3cb7'
                  }`,
                  color: storeInfo?.theme?.colors?.primary_color
                    ? storeInfo?.theme?.colors?.primary_color
                    : '#5b3bb7',
                }}
                className={styles.writeReviewBtn}
                onClick={handleReviewModalOpen}
                data-tip
                data-for="write-a-review"
              >
                {loggedInUserReview?.review ? 'Edit Review' : 'Write a Review'}
              </button>
              <ReactTooltip
                id="write-a-review"
                place="bottom"
                effect="solid"
                multiline={true}
                padding="8px"
                disable={shouldReviewItem() || deviceWidth <= 767}
              >
                <p className="mv0 fw5 f12px">
                  Only buyers of this item can submit reviews. If you have any
                  <br />
                  concerns, please share them with us and we would look into it.
                </p>
              </ReactTooltip>
            </>
          ) : null}
        </div>
        {data?.ratings_count ? (
          <div rel="schema:aggregateRating">
            <div className="flex items-center pt20px" typeof="schema:AggregateRating">
              <p className="mv0 fw6 f28px c44454A pr8px" property="schema:ratingValue">
                {data?.review_rating && data?.review_rating[0]?.avg_rating.toFixed(1)}
              </p>
              <div className="flex items-end">
                <RatingStar
                  rating={data?.review_rating && data?.review_rating[0]?.avg_rating}
                />
                <p className="mv0 pl16px fw5 f14px c44454A">
                  <span property="schema:ratingCount">{data?.ratings_count}</span> Ratings
                  & {data?.reviews_count} Reviews
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="pt20px">
          {reviewRatings?.map((review, index) => (
            <CustomerReviewReply
              reviewRatings={reviewRatings}
              review={review}
              key={index}
              index={index}
            />
          ))}
        </div>
        {publishedReviewsAndUserReview?.length > 8 ? (
          <p className="fw6 f18px c28292C pointer" onClick={toggleShowAllReviews}>
            {showAllReviews
              ? 'See Less'
              : `See all ${publishedReviewsAndUserReview?.length} reviews`}
          </p>
        ) : null}
      </div>
      <CustomerReviewModal
        open={openReviewModal}
        onClose={() => setOpenReviewModal(false)}
        onSubmit={handleCustomerReviewModalClose}
        product={product}
        loggedInUserReview={loggedInUserReview}
      />
      <VerifiedBuyerModal
        open={openVerifiedModal}
        onClose={() => setOpenVerifiedModal(false)}
      />
    </>
  );
};

export default ReviewsSection;
