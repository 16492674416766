/** MODULES */
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from './../redux/store';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';

/** STYLES */
import './../styles/index.scss';
// import 'react-toastify/dist/ReactToastify.css';
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
import '@dotpe/additional-pages/dist/styles/index.scss';
import '@/components/DesignSystem';

import { SSRSelectorContext } from '@/redux/useSSRSelector';
import { setRoutes } from '@/redux/actions';
import { WidgetDndContext, useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { GlobalCfeLoader } from 'src/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';

function MyApp(appProps: AppProps<any>) {
  const { Component, pageProps } = appProps;
  const router = useRouter();
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (route) => {
      store.dispatch(setRoutes({ next: route, current: window.location.pathname }));
      setLoading(true);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, []);

  const domain =
    pageProps?.domain || pageProps?.ssrStore?.storeReducer?.store?.domain || '';
  const userAgent = pageProps?.userAgent || '';

  return (
    <Provider store={store}>
      <SSRSelectorContext.Provider value={{ domain, userAgent }}>
        <WidgetDndContext>
          {loading && (
            <div className="global-loader">
              <GlobalCfeLoader
                type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
                color={globalStyle?.appLoader?.color}
              />
            </div>
          )}
          <Component {...pageProps} />
        </WidgetDndContext>
      </SSRSelectorContext.Provider>
    </Provider>
  );
}

export default MyApp;
