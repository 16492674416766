// // location
// export const FETCH_LOCATION = "LOCATION/FETCH_LOCATION";
// export const SAVE_LOCATION = "LOCATION/SAVE_LOCATION";
// export const SUBMIT_LOCATION = "LOCATION/SUBMIT_LOCATION";

// storeinfo
export const SAVE_STORE_INFO = 'STOREINFO/SAVE_STORE_INFO';
export const FETCH_STORE_BY_SUBDOMAIN = 'STOREINFO/FETCH_STORE_BY_SUBDOMAIN';
export const GET_B2B_RFQ_INPUT_FIELDS = 'STOREINFO/GET_B2B_RFQ_INPUT_FIELDS';
export const SAVE_B2B_RFQ_INPUT_FIELDS = 'STOREINFO/SAVE_B2B_RFQ_INPUT_FIELDS';
export const TOGGLE_RFQ_FORM_MODAL = 'STOREINFO/TOGGLE_RFQ_FORM_MODAL';
export const UPDATE_THEME_INFO = 'STOREINFO/UPDATE_THEME_INFO';

// Cart
export const SAVE_CART = 'CART/SAVE_CART';
export const EMPTY_CART = 'CART/EMPTY_CART';
export const SAVE_ORDER_INSTRUCTION = 'CART/SAVE_ORDER_INSTRUCTION';
export const VALIDATE_CART = 'CART/VALIDATE_CART';
export const APPLY_COUPON = 'CART/APPLY_COUPON';
export const SET_CART = 'CART/SET_CART';
export const SET_B2B_CART = 'CART/SET_B2B_CART';
export const GET_CART_BY_ID = 'CART/GET_CART_BY_ID';
export const UPDATE_CART_ID = 'CART/UPDATE_CART_ID';
export const TOGGLE_ORDER_TYPE = 'CART/TOGGLE_ORDER_TYPE';
export const UPDATE_B2B_CART = 'CART/UPDATE_B2B_CART';
export const UPDATE_RFQ_DETAILS = 'CART/UPDATE_RFQ_DETAILS';
export const SAVE_LATEST_SYNCED_CART = 'CART/SAVE_LATEST_SYNCED_CART';

// //Order
export const PLACE_ORDER = 'ORDER/PLACE_ORDER';
export const SAVE_ORDER = 'ORDER/SAVE_ORDER';
export const FETCH_ORDER_DETAILS_BY_ID = 'ORDER/FETCH_ORDER_DETAILS_BY_ID';
export const SAVE_ORDER_DETAILS = 'ORDER/SAVE_ORDER_DETAILS';
// export const EMPTY_ORDER_DETAILS =
('ORDER/EMPTY_ORDER_DETAILS');
export const FETCH_ORDER_BY_HASH = 'ORDER/FETCH_ORDER_BY_HASH';
export const FETCH_LOGGED_IN_ORDER_BY_HASH = 'ORDER/FETCH_LOGGED_IN_ORDER_BY_HASH';
// export const SAVE_ORDER_HASH = "ORDER/SAVE_ORDER_HASH";
export const FETCH_ORDER_LIST = 'ORDER/FETCH_ORDER_LIST';
export const SAVE_ORDER_LIST_PENDING = 'ORDER/SAVE_ORDER_LIST_PENDING';
export const SAVE_ORDER_LIST_COMPLETE = 'ORDER/SAVE_ORDER_LIST_COMPLETE';
export const SAVE_ORDER_LIST_CONFIG = 'ORDER/SAVE_ORDER_LIST_CONFIG';
export const GET_STORE_ORDER = 'ORDER/GET_STORE_ORDER';
// export const SHOW_ORDER_LOADER = "ORDER/SHOW_ORDER_LOADER";
export const UPDATE_ORDER_STATE = 'ORDER/UPDATE_ORDER_STATE';
export const GET_GST_INVOICE = 'ORDER/GET_GST_INVOICE';
export const SAVE_GST_INVOICE = 'ORDER/SAVE_GST_INVOICE';
export const SAVE_PARENT_ORDER_DETAILS = 'ORDER/SAVE_PARENT_ORDER_DETAILS';
export const CANCEL_ORDER = 'ORDER/CANCEL_ORDER';
export const SET_ORDER_STATUS_ERROR_FOR_INVALID_USER =
  'ORDER/SET_ORDER_STATUS_ERROR_FOR_INVALID_USER';

// //payment
export const INIT_PAYMENT = 'PAYMENT/INIT_PAYMENT';
export const INIT_PAYMENT_FOR_QR = 'PAYMENT/INIT_PAYMENT_FOR_QR';
export const POLL_PAYMENT_STATUS = 'PAYMENT/POLL_PAYMENT_STATUS';
export const VERIFY_PAYMENT = 'PAYMENT/VERIFY_PAYMENT';
export const FETCH_PAYMENT_METHODS = 'PAYMENT/FETCH_PAYMENT_METHODS';
export const SAVE_PAYMENT_METHODS = 'PAYMENT/SAVE_PAYMENT_METHODS';
export const SAVE_PAYMENT_INFORMATIONS = 'PAYMENT/SAVE_PAYMENT_INFORMATIONS';
export const SAVE_POST_PAYMENT_DATA = 'PAYMENT/SAVE_POST_PAYMENT_DATA';
export const REDIRECT_AFTER_PAYMENT = 'ORDER/REDIRECT_AFTER_PAYMENT';
export const INIT_LAZY_PAY_PAYMENT = 'PAYMENT/INIIATE_LAZY_PAY_PAYMENT';
export const VERIFY_LAZY_PAY_OTP = 'PAYMENT/VERIFY_LAZY_PAY_OTP';

export const GET_AVAILABLE_PAYMENT_METHODS = 'PAYMENTMETHODS/GET_PAYMENT_METHODS';
export const SAVE_AVAILABLE_PAYMENT_METHODS =
  'PAYMENTMETHODS/SAVE_AVAILABLE_PAYMENT_METHODS';
export const CHECK_CARD_INFO = 'PAYMENTMETHODS/CHECK_CARD_INFO';
export const PRE_PAYMENT_FOR_MDR_TRX = 'PAYMENTMETHODS/PRE_PAYMENT_FOR_MDR_TRX';
export const SET_CUSTOMER_SELECTED_PAYMENT_OPTION =
  'PAYMENTMETHODS/SET_CUSTOMER_SELECTED_PAYMENT_OPTION';
export const TOGGLE_PARTIAL_PAYMENT_MODE = 'PAYMENTMETHODS/TOGGLE_PARTIAL_PAYMENT_MODE';
export const SET_PAYMENT_MODAL_OPTIONS_VISIBILITY =
  'PAYMENT/SET_PAYMENT_MODAL_OPTIONS_VISIBILITY';

// Auth
export const GENERATE_OTP_FOR_EMAIL = 'OTP/GENERATE_OTP_FOR_EMAIL';
export const VERIFY_OTP_FOR_EMAIL = `OTP/VERIFY_OTP_FOR_EMAIL`;
export const GENERATE_OTP = 'OTP/GENERATE_OTP';
export const VERIFY_OTP = 'OTP/VERIFY_OTP';
export const AUTHENTICATE_USER = 'USER/AUTHENTICATE_USER';
export const SAVE_PHONE_NUMBER = 'PHONE/SAVE_PHONE_NUMBER';
export const SAVE_EMAIL_ID = 'EMAIL/SAVE_EMAIL_ID';
export const SAVE_USER_DETAILS = 'USER/SAVE_USER_DETAILS';
export const DELETE_USER_ACCOUNT = 'USER/DELETE_USER_ACCOUNT';
export const AUTH_TRUECALLER_DETAILS = 'USER/AUTHENTICATE_TRUECALLER';
export const LOGIN_WITH_GMAIL = 'LOGIN/LOGIN_WITH_GMAIL';

// Location
export const FETCH_ADDRESS_LIST = 'USER/FETCH_ADDRESS_LIST';
export const SAVE_ADDRESS_LIST = 'USER/SAVE_ADDRESS_LIST';
export const SAVE_ADDRESS = 'USER/SAVE_ADDRESS';
export const SAVE_SELECTED_ADDRESS = 'USER/SAVE_SELECTED_ADDRESS';
export const REMOVE_SELECTED_ADDRESS = 'USER/REMOVE_SELECTED_ADDRESS';
export const FETCH_CITY_AND_STATE_FROM_PINCODE = 'USER/FETCH_CITY_AND_STATE_FROM_PINCODE';
export const FETCH_COUNTRIES_LIST = 'USER/FETCH_COUNTRIES_LIST';

export const CHECK_IS_ORDER_LOCATION_SERVICEABLE =
  'USER/CHECK_IS_ORDER_LOCATION_SERVICEABLE';

// //Product list
// export const FETCH_QUICK_ADDS = "PRODUCT/FETCH_QUICK_ADDS";
// export const SAVE_QUICK_ADDS = "PRODUCT/SAVE_QUICK_ADDS";

// Catalog Products
export const FETCH_CATALOG_ITEMS_BASICS_BY_STOREID =
  'PRODUCT/FETCH_CATALOG_ITEMS_BASICS_BY_STOREID';
export const SAVE_CATALOG_ITEMS_BASICS_BY_STOREID =
  'PRODUCT/SAVE_CATALOG_ITEMS_BASICS_BY_STOREID';
export const SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES =
  'PRODUCT/SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES';
export const RESET_CATALOGS_LIST_BASED_ON_CATEGORIES =
  'PRODUCT/RESET_CATALOGS_LIST_BASED_ON_CATEGORIES';
export const SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG =
  'PAGINATION/SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG';
export const SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG =
  'PAGINATION/SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG';
export const RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG =
  'PAGINATION/RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG';
export const SET_MB5_SORT_AND_FILTER_CONFIG = 'PAGINATION/SET_MB5_SORT_AND_FILTER_CONFIG';
export const SET_MB6_PAGINATION_CONFIG = 'PAGINATION/SET_MB6_PAGINATION_CONFIG';
export const SET_ROUTE_CURRENT = 'SET_ROUTE_CURRENT';
export const SET_ROUTE_NEXT = 'SET_ROUTE_NEXT';
export const SET_ROUTES = 'SET_ROUTES';
export const RESET_CATALOG_ITEMS = 'RESET_CATALOG_ITEMS';

export const FETCH_CATALOG_ITEMS_BY_CATEGORY_ID =
  'PRODUCT/FETCH_CATALOG_ITEMS_BY_CATEGORY_ID';
export const SAVE_CATALOG_CATEGORIES = 'PRODUCT/SAVE_CATALOG_CATEGORIES';
export const SAVE_CATALOG_TAGS = 'PRODUCT/SAVE_CATALOG_TAGS';
// export const CHANGE_LOADER_STATE = "PRODUCT/CHANGE_LOADER_STATE";
// export const FETCH_CATALOG_ITEM = "PRODUCT/FETCH_CATALOG_ITEM";
// export const SAVE_CATALOG_ITEM_PDP = "PRODUCT/SAVE_CATALOG_ITEM_PDP";
export const FETCH_SIMILAR_CATALOG_ITEMS = 'PRODUCT/FETCH_SIMILAR_CATALOG_ITEMS';
export const SAVE_SIMILAR_CATALOG_ITEMS = 'PRODUCT/SAVE_SIMILAR_CATALOG_ITEMS';
export const SEARCH_PRODUCT_ITEMS = 'PRODUCT/SEARCH_PRODUCT_ITEMS';
export const SAVE_SEARCHED_PRODUCT_ITEMS = 'PRODUCT/SAVE_SEARCHED_PRODUCT_ITEMS';
export const FETCH_CATALOG_ITEM_BY_ID = 'PRODUCT/FETCH_CATALOG_ITEM_BY_ID';
export const SAVE_CATALOG_ITEM_BY_ID = 'PRODUCT/SAVE_CATALOG_ITEM_BY_ID';
export const SET_CATALOG_PRODUCT_FILTER = 'PRODUCT/SET_CATALOG_PRODUCT_FILTER';
export const SAVE_COLLECTIONS = 'COLLECTIONS/SAVE_COLLECTIONS';
export const FETCH_COLLECTION_CATALOG_ITEM_BY_ID =
  'COLLECTIONS/FETCH_COLLECTION_CATALOG_ITEM_BY_ID';
export const SAVE_COLLECTION_CATALOG_ITEMS = 'COLLECTIONS/SAVE_COLLECTION_CATALOG_ITEMS';
export const GET_CUSTOMER_REVIEW_SETTINGS = 'GET_CUSTOMER_REVIEW_SETTINGS';
export const SAVE_REVIEW_SETTINGS = 'SAVE_REVIEW_SETTINGS';
export const PUBLISH_REVIEW = 'PUBLISH_REVIEW';
export const VERIFY_BUYER_FOR_ITEM = 'VERIFY_BUYER_FOR_ITEM';
export const GET_FILTERED_ITEMS_OPTIONS = 'PRODUCT/GET_FILTERED_ITEMS_OPTIONS';
export const SAVE_FILTERED_ITEMS = 'PRODUCT/SAVE_FILTERED_ITEMS';
export const SAVE_FILTERED_OPTIONS = 'PRODUCT/SAVE_FILTERED_OPTIONS';
export const RESET_FILTERED_ITEMS_OPTIONS = 'PRODUCT/RESET_FILTERED_ITEMS_OPTIONS';
export const FILTERS_OPTION_APPLY_COUNTER = 'PRODUCT/FILTERS_OPTION_APPLY_COUNTER';
export const SET_FILTER_OPTION = 'PRODUCT/SET_FILTER_OPTION';
export const RESET_FILTER_ITEMS = 'PRODUCT/SET_FILTER_ITEMS';

export const OPEN_PRODUCT_PERSONALIZATION_POPUP =
  'PRODUCT/OPEN_PRODUCT_PERSONALIZATION_POPUP';
export const CLOSE_PRODUCT_PERSONALIZATION_POPUP =
  'PRODUCT/CLOSE_PRODUCT_PERSONALIZATION_POPUP';
export const ADD_PRODUCT_PERSONALIZATION_DATA =
  'PRODUCT/ADD_PRODUCT_PERSONALIZATION_DATA';
export const EDIT_PRODUCT_PERSONALIZATIO_DATA =
  'PRODUCT/EDIT_PRODUCT_PERSONALIZATIO_DATA';
export const ADD_TEXT_PRODUCT_PERSONALIZATION =
  'PRODUCT/ADD_TEXT_PRODUCT_PERSONALIZATION';
export const INCREMENT_PRODUCT_PERSONALIZATION_DATA =
  'PRODUCT/INCREMENT_PRODUCT_PERSONALIZATION_DATA';
export const DECREMENT_PRODUCT_PERSONALIZATION_DATA =
  'PRODUCT/DECREMENT_PRODUCT_PERSONALIZATION_DATA';
export const EDIT_CART_PRODUCT_ITEM = 'PRODUCT/EDIT_CART_PRODUCT_ITEM';
export const SET_PRODUCT_DATA_PERSONALISED = 'PRODUCT/SET_PRODUCT_DATA_PERSONALISED';
export const SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT =
  'PRODUCT/SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT';

// //Product Description Screen
// export const FETCH_PDP_DATA = "PRODUCT/FETCH_PDP_DATA";
export const SAVE_PDP_DATA = 'PRODUCT/SAVE_PDP_DATA';

// COMMON TYPES
export const TOGGLE_SEARCHBOX_STATE = 'COMMON/TOGGLE_SEARCHBOX_STATE'; // WebSearchBox
export const SET_ATHS_POPUP_VISIBILITY = 'COMMON/SET_ATHS_POPUP_VISIBILITY'; // ATHS Popup Visibility
export const SAVE_ATHS_EVENT = 'COMMON/SAVE_ATHS_EVENT'; // Save ATHS Default event object
export const SET_LOADER_STATE = 'COMMON/SET_LOADER_STATE'; // Save Loader State
export const SET_HEADER_HEIGHT = 'COMMON/SET_HEADER_HEIGHT'; // get height of header for calculation
export const SET_SUBHEADER_HEIGHT = 'COMMON/SET_SUBHEADER_HEIGHT'; // get height of subheader for calculation
export const SET_HEROBANNER_HEIGHT = 'COMMON/SET_HEROBANNER_HEIGHT'; // get height of herobanner for calculation
export const RESET_HEIGHT = 'COMMON/RESET_HEIGHT'; // reset all height
export const SAVE_PRODUCT_LOADER_ID = 'COMMON/SAVE_PRODUCT_LOADER_ID';
export const SAVE_DETAILED_ITEM_BY_ID = 'COMMON/SAVE_DETAILED_ITEM_BY_ID';
export const SET_VARIANT_MODEL_VISIBILITY = 'COMMON/SET_VARIANT_MODEL_VISIBILITY';
export const SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY =
  'COMMON/SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY';
export const SET_SELECTED_VARIANT_MODEL_VISIBILITY =
  'COMMON/SET_SELECTED_VARIANT_MODEL_VISIBILITY';
export const SET_PAYMENT_MODEL_VISIBILITY = 'COMMON/SET_PAYMENT_MODEL_VISIBILITY';
export const TOGGLE_LAZYPAY_AUTH_MODAL = 'COMMON/TOGGLE_LAZYPAY_AUTH_MODAL';
export const TOGGLE_MOBILE_VIEW = 'COMMON/TOGGLE_MOBILE_VIEW';
export const TOGGLE_SET_CART_LOADER_STATE = 'COMMON/SET_CART_LOADER_STATE';
export const SAVE_CREATE_LIST = 'COMMON/SAVE_CREATE_LIST';
export const SAVE_HEADER_HEIGHT = 'COMMON/SAVE_HEADER_HEIGHT';
export const SET_FILTER_LOADER = 'COMMON/SET_FILTER_LOADER';
export const SAVE_HEADER_REF = 'COMMON/SAVE_HEADER_REF';
export const SET_GLOBAL_LOADER = 'COMMON/SET_GLOBAL_LOADER';
export const PREPAID_LIMIT_DISABLE_PAY_NOW = 'COMMON/PREPAID_LIMIT_DISABLE_PAY_NOW';
export const HIDE_FILTERS_CATEGORY_AND_COLLECTION =
  'COMMON/HIDE_FILTERS_CATEGORY_AND_COLLECTION';
export const GET_EXIT_GATING_DATA = 'COMMON/GET_EXIT_GATING_DATA';
export const SET_EXIT_GATING_DATA = 'COMMON/SET_EXIT_GATING_DATA';
export const SET_SHOW_INSTALL_APP_OVERLAY = 'COMMON/SET_SHOW_INSTALL_APP_OVERLAY';
export const TOGGLE_LOGOUT_CONFIRM_MODAL = 'COMMON/TOGGLE_LOGOUT_CONFIRM_MODAL';
export const UPDATE_COMMON_REDUCER = 'COMMON/UPDATE_COMMON_REDUCER';
export const TOGGLE_PROMO_REMOVAL_ALERT_MODAL = 'COMMON/TOGGLE_PROMO_REMOVAL_ALERT_MODAL';
export const TRIGGER_CART_UPDATE_NOTIFIER = 'COMMON/TRIGGER_CART_UPDATE_NOTIFIER';

// Spotlight
export const FETCH_SPOTLIGHT_CITIES = 'SPOTLIGHT/FETCH_SPOTLIGHT_CITIES';
export const SAVE_SPOTLIGHT_CITIES = 'SPOTLIGHT/SAVE_SPOTLIGHT_CITIES';
export const SAVE_DEFAULT_CITY = 'SPOTLIGHT/SAVE_DEFAULT_CITY';
export const SAVE_SELECTED_CITY = 'SPOTLIGHT/SAVE_SELECTED_CITY';
export const FETCH_STORES_LIST = 'SPOTLIGHT/FETCH_STORES_LIST';
export const SAVE_STORES_LIST = 'SPOTLIGHT/SAVE_STORES_LIST';
export const EMPTY_STORES_LIST = 'SPOTLIGHT/EMPTY_STORES_LIST';
export const FETCH_BUSSINESS_TYPES = 'SPOTLIGHT/FETCH_BUSSINESS_TYPES';
export const SAVE_BUSSINESS_TYPES = 'SPOTLIGHT/SAVE_BUSSINESS_TYPES';
export const SAVE_LOCATION_CORDS = 'SPOTLIGHT/SAVE_LOCATION_CORDS';
export const SEARCH_SPOTLIGHT_STORES = 'SPOTLIGHT/SEARCH_STORES_LIST';
export const SAVE_SEARCHED_SPOTLIGHT_STORES = 'SPOTLIGHT/SAVE_SEARCHED_SPOTLIGHT_STORES';

// Event Log
export const PUSH_EVENTS_LOG = 'LOG/PUSH_EVENTS_LOG';

// Error
export const SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY =
  'ERROR/SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY';
export const SAVE_CART_VALIDATION_DATA = 'ERROR/SAVE_CART_VALIDATION_DATA';
export const SET_NOT_ACCEPTING_ORDERS_MODAL_VISIBILITY = 'ERROR/SET_NOT_ACCEPTING_ORDERS';

// COUPONS/PROMO TYPES
export const FETCH_COUPONS_LIST = 'COUPONS/FETCH_COUPONS_LIST';
export const SAVE_COUPONS_LIST = 'COUPONS/SAVE_COUPONS_LIST';
export const FETCH_PAYMENT_OFFERS_LIST = 'COUPONS/FETCH_PAYMENT_OFFERS_LIST';
export const SAVE_PAYMENT_OFFERS_LIST = 'COUPONS/SAVE_PAYMENT_OFFERS_LIST';
export const VERIFY_COUPON = 'COUPONS/VERIFY_COUPON';
export const VERIFY_PAYMENT_OFFER = 'COUPONS/VERIFY_PAYMENT_OFFER';
export const FETCH_EVALUATED_COUPONS = 'COUPONS/FETCH_EVALUATED_COUPONS';

export const GET_CART_PAGE_PROMOS = 'PROMOS/GET_CART_PAGE_PROMOS';
export const GET_ALL_PROMOS = 'PROMOS/GET_ALL_PROMOS';
export const TOGGLE_PROMO_DETAIL_MODAL = 'PROMOS/TOGGLE_PROMO_DETAIL_MODAL';
export const GET_PROMO_DATA_BY_ID = 'PROMOS/GET_PROMO_DATA_BY_ID';

export const GET_PROMOS_BY_TYPE_AND_TYPE_ID = 'PROMOS/GET_PROMOS_BY_TYPE_AND_TYPE_ID';
export const GET_PROMO_ASSOCIATED_IDS = 'PROMOS/GET_PROMO_ASSOCIATED_IDS';
export const GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS = `PROMOS/GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS`;
export const GET_BOGO_REQUIRED_PRODUCTS_IN_CART = `PROMOS/GET_BOGO_REQUIRED_PRODUCTS_IN_CART`;
export const GET_SATISFIED_BOGO_PROMOS = 'PROMOS/GET_SATISFIED_BOGO_PROMOS';
export const APPLY_PROMO = 'PROMOS/APPLY_PROMO';

// ADDITIONAL PAGE TYPES
export const GET_ADDITIONAL_PAGE = 'ADDITIONALPAGE/GET_ADDITIONAL_PAGE';
export const GET_ADDITIONAL_PAGE_DATA_BY_SLUG =
  'ADDITIONALPAGE/GET_ADDITIONAL_PAGE_DATA_BY_SLUG';
export const SAVE_ADDITIONAL_PAGE = 'ADDITIONALPAGE/SAVE_ADDITIONAL_PAGE';
export const SAVE_ADDITIONAL_PAGE_LIST = 'ADDITIONALPAGE/SAVE_ADDITIONAL_PAGE_LIST';
export const GET_NAVIGATION_PAGES_LIST = 'ADDITIONALPAGE/GET_NAVIGATION_PAGES_LIST';
export const SAVE_NAVIGATION_PAGES_LIST = 'ADDITIONALPAGE/SAVE_NAVIGATION_PAGES_LIST';
export const GET_STATIC_WIDGET_DATA = 'ADDITIONALPAGE/GET_STATIC_WIDGET_DATA';
export const UPDATE_ADDITIONAL_PAGE_REDUCER =
  'ADDITIONALPAGE/UPDATE_ADDITIONAL_PAGE_REDUCER';
// JSON widgets
export const INITIALIZE_JSON_WIDGETS_DATA = 'WIDGETS/INITIALIZE_JSON_WIDGETS_DATA';

// Store Policies
export const GET_STORE_POLICIES = 'STOREPOLICY/GET_STORE_POLICIES';
export const SAVE_STORE_POLICIES = 'STOREPOLICY/SAVE_STORE_POLICIES';
// LEAD_GENERATION

export const GET_STORE_ACTIVE_LEADGEN_TEMPLATE = 'LEAD/GET_STORE_ACTIVE_LEADGEN_TEMPLATE';
export const UPDATE_CUSTOM_LEAD = 'LEAD/UPDATE_CUSTOM_LEAD';
export const SAVE_LEAD_GENINFO = 'LEAD/SAVE_LEAD_GENINFO';

// EMI related action types
export const GET_EMI_OPTIONS_FOR_CARD = 'EMI/GET_EMI_OPTIONS_FOR_CARD';
export const GET_ALL_EMI_OPTIONS = 'EMI/GET_ALL_EMI_OPTIONS';
export const SAVE_EMI_OPTION_FOR_CARD = 'EMI/SAVE_EMI_OPTION_FOR_CARD';
export const SAVE_ALL_EMI_OPTIONS = 'EMI/SAVE_ALL_EMI_OPTIONS';
export const SET_EMI_OPTION = 'EMI/SET_EMI_OPTION';

export const SET_LOGIN = 'LOGIN/SET_LOGIN';
export const SET_LOGOUT = 'LOGOUT/SET_LOGOUT';
export const TOGGLE_VERIFY_OTP = 'TOGGLE/TOGGLE_VERIFY_OTP';

export const SET_TRUECALLER_PRESENT = 'TRUECALLER/TRUECALLER_PRESENT';
export const SET_TRUECALLER_ABSENT = 'TRUECALLER/TRUECALLER_ABSENT';

export const COUPON_BANNER_PRESENT = 'COMMON/COUPON_BANNER_PRESENT';
export const COUPON_BANNER_ABSENT = 'COMMON/COUPON_BANNER_ABSENT';

// Pincode based Delivery actions
export const GET_ITEMS_DELIVERY_CHARGE = 'DELIVERY/GET_ITEMS_DELIVERY_CHARGE';
export const SET_ITEMS_DELIVERY_CHARGE = 'DELIVERY/SET_ITEMS_DELIVERY_CHARGE';
export const GET_SERVICEABLE_ADDRESS = 'DELIVERY/GET_SERVICABLE_ADDRESS';
export const SET_SERVICEABLE_ADDRESS = 'DELIVERY/SET_SERVICABLE_ADDRESS';
export const GET_ESTIMATED_DELVIVERY_TIME = 'DELIVERY/GET_ESTIMATED_DELVIVERY_TIME';

// True -> ADD DELIVERY Form is Opened.
export const SET_TOGGLE_ADD_DELIVERY_FORM = 'COMMONREDUCER/SET_TOGGLE_ADD_DELIVERY_FORM';

// Notify Product Inquiry
export const ADD_NOTIFY_PRODUCT_INQUIRY = 'NOTIFY_PRODUCT/ADD_NOTIFY_PRODUCT_INQUIRY';
export const FETCH_FEATURE_LOCKS = 'SUBSCRIPTION/FETCH_FEATURE_LOCKS';
export const SAVE_FEATURE_LOCKS = 'SUBSCRIPTION/SAVE_FEATURE_LOCKS';

// Website Builder Action Types
export const GET_WIDGET_THEME_INFO = 'WIDGETS/GET_WIDGET_THEME_INFO';
export const SAVE_WIDGET_THEME_INFO = 'WIDGETS/SAVE_WIDGET_THEME_INFO';
export const SAVE_PAGE_LAYOUT_WIDGETS = 'WIDGETS/SAVE_PAGE_LAYOUT_WIDGETS';
export const ADD_WIDGET = 'WIDGETS/ADD_WIDGET';
export const UPDATE_WIDGET = 'WIDGETS/UPDATE_WIDGET';
export const SUBMIT_FORM_DATA = 'WIDGETS/SUBMIT_FORM_DATA';
export const TOGGLE_FORM_WIDGET = 'WIDGETS/TOGGLE_FORM_WIDGET';
export const SAVE_FLOATING_BUTTON_DATA = 'WIDGETS/SAVE_FLOATING_BUTTON_DATA';
export const SET_ACTIVE_STYLE_TEMPLATE = 'WIDGETS/SET_ACTIVE_STYLE_TEMPLATE';
export const SAVE_ACTIVE_STYLE_TEMPLATE = 'WIDGETS/SAVE_ACTIVE_STYLE_TEMPLATE';
export const SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE =
  'WIDGETS/SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE';
export const SAVE_FORM_DATA = 'WIDGETS/SAVE_FORM_DATA';
export const ADD_MEDIA_TO_STORE = 'WIDGETS/ADD_MEDIA_TO_STORE';
export const UPDATE_MEDIA_TO_STORE = 'WIDGETS/UPDATE_MEDIA_TO_STORE';
export const DELETE_MEDIA_FROM_STORE = 'WIDGETS/DELETE_MEDIA_FROM_STORE';
export const UPLOAD_PDF_TO_S3 = 'WIDGETS/UPLOAD_PDF_TO_S3';
export const UPDATE_THEME_BUILDER_DATA = 'WIDGETS/UPDATE_THEME_BUILDER_DATA';
export const CREATE_THEME = 'WIDGETS/CREATE_THEME';
export const UPDATE_THEME = 'WIDGETS/UPDATE_THEME';
export const PUBLISH_THEME = 'WIDGETS/PUBLISH_THEME';
export const SET_THEME_BUILDER_FLOW = 'WIDGETS/SET_THEME_BUILDER_FLOW';
export const SAVE_CURRENT_THEME_DATA = 'WIDGETS/SAVE_CURRENT_THEME_DATA';

/**  Upload Media S3 Action */
export const UPLOAD_MEDIA_TO_S3 = 'ADD/UPLOAD_MEDIA_TO_S3';

export const FETCH_FOOTER_PAYMENT_MODES_LIST = 'FOOTER/FETCH_FOOTER_PAYMENT_MODES_LIST';
export const SAVE_FOOTER_PAYMENT_MODES_LIST = 'FOOTER/SAVE_FOOTER_PAYMENT_MODES_LIST';
