import { getTotalCartAmounts } from './getTotalCartAmounts';
import { toastNotifyInfo } from '@/components/Common/Toast';

const updatePersonalizationQuantity = (cart, idx, ppIdx, type) => {
  const personalizations = cart[idx]?.personalization_info?.personalizations;

  if (personalizations && personalizations[ppIdx]) {
    if (personalizations[ppIdx].quantity === 1) {
      // Remove the personalization item if its quantity is 1
      personalizations.splice(ppIdx, 1);
    } else {
      // Decrement the personalization quantity
      personalizations[ppIdx].quantity -= 1;
    }
  }
};

/**
 * Helper function for addition of the product
 * item to the cart for update the cart
 * @param {string} type
 * @param {object} item
 * @param {Array} cart
 * @return {Array}
 */
export const mutateItemObjectWithCartData = (
  type,
  item,
  cart,
  personalizedProductData = null,
  ppIdx = null,
  personalizationLocalState = null
) => {
  try {
    const tempCart = JSON.parse(JSON.stringify(cart.items));
    let idx;
    // create important keys in the Item
    item = {
      ...item,
      item_name: item.name,
      quantity: item.quantity ? item.quantity : 1,
      amount: (item.quantity ? item.quantity : 1) * item.discounted_price,
      actual_price: item.price,
      discounted_price: item.discounted_price,
      item_id: item.id || item.item_id,
      item_type: 'catalog',
    };
    const personalization_info = {};
    switch (type) {
      // case for increase in item quantity
      case 'inc':
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
        // if managed inventory is acvtivated and,
        // quantity is same as available quantity,
        // disallow addition
        if (
          tempCart[idx].managed_inventory &&
          tempCart[idx].quantity === tempCart[idx].available_quantity
        ) {
          toastNotifyInfo('No more units available for purchase');
        } else {
          tempCart[idx].quantity = tempCart[idx].quantity + 1;
          tempCart[idx].amount = tempCart[idx].quantity * tempCart[idx].discounted_price;
          if (personalizedProductData && ppIdx !== undefined) {
            tempCart[idx].personalization_info.personalizations[ppIdx] = {
              ...tempCart[idx].personalization_info.personalizations[ppIdx],
              quantity:
                tempCart[idx].personalization_info.personalizations[ppIdx]?.quantity + 1,
            };
          }
        }
        break;
      // case for descrease in item quantity
      case 'dec':
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);
        // if the quantity is one i.e you cannot reduce the quantity more so remove that item
        if (tempCart[idx].quantity === 1) {
          tempCart.splice(idx, 1);
        } else {
          tempCart[idx].quantity = tempCart[idx].quantity - 1;
          tempCart[idx].amount = tempCart[idx].quantity * tempCart[idx].discounted_price;
          if (personalizedProductData && ppIdx !== undefined) {
            updatePersonalizationQuantity(tempCart, idx, ppIdx);
          }
        }
        break;
      // case for the add item in cart
      case 'add':
        idx = tempCart.findIndex((ele) => ele.item_id === item.item_id);

        if (personalizedProductData) {
          const personalizations = [{ ...personalizedProductData, quantity: 1 }];
          personalization_info['personalizations'] = [...personalizations];
        }

        if (idx === -1) {
          tempCart.push({
            ...item,
            personalization_info: personalizedProductData ? personalization_info : null,
          });
        } else {
          tempCart[idx].quantity = tempCart[idx].quantity + 1;
          tempCart[idx].personalization_info.personalizations.push(
            personalization_info['personalizations'][0]
          );
          tempCart[idx].amount = tempCart[idx].quantity * tempCart[idx].discounted_price;
        }
        break;
      case 'save':
        idx = tempCart?.findIndex((ele) => ele?.item_id === item?.item_id);
        if (personalizedProductData) {
          tempCart[idx].personalization_info['personalizations'][ppIdx] = {
            ...personalizedProductData,
          };
        }
        break;
      case 'confirm':
        idx = tempCart?.findIndex((ele) => ele?.item_id === item?.item_id);

        if (personalizationLocalState) {
          const finalPersonalizedData = personalizationLocalState.filter(
            (ele) => ele?.quantity !== 0
          );
          tempCart[idx].personalization_info['personalizations'] = [
            ...finalPersonalizedData,
          ];

          const totalPersonalizationQuantity = tempCart?.[
            idx
          ]?.personalization_info?.personalizations?.reduce(
            (total, personalization) => total + personalization?.quantity,
            0
          );
          tempCart[idx].quantity = totalPersonalizationQuantity;
          tempCart[idx].amount =
            tempCart?.[idx]?.quantity * tempCart?.[idx]?.discounted_price;

          if (
            tempCart?.[idx]?.managed_inventory &&
            tempCart?.[idx]?.quantity > tempCart?.[idx]?.available_quantity
          ) {
            toastNotifyInfo('Purchase quantity exceeds the available quantity');
            return;
          }

          if (tempCart?.[idx]?.quantity === 0) {
            tempCart?.splice(idx, 1);
          }
        }
        break;
      default:
        break;
    }

    const amount_values = getTotalCartAmounts(tempCart);

    return {
      ...cart,
      items: tempCart,
      ...amount_values,
    };
  } catch (err) {
    console.log(err);
    return cart;
  }
};
