import styled from 'styled-components';
import { roundnessCalculator } from '../util';

export const CartButtonBody = styled.div`
  cursor: pointer;
  border: 1px solid;
  border-color: ${(props) => (props.primaryColor ? props.primaryColor : '#000000')};
  color: ${(props) => (props.primaryColor ? props.primaryColor : '#000000')};
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.isPDPPage
      ? props.centered
        ? 'center'
        : 'space-between'
      : props.centered
      ? 'center'
      : 'space-between'};
  text-transform: capitalize;
  outline: none;
  width: ${(props) => (props?.width ? props?.width : 'fit-content')};
  border-radius: ${(props) => roundnessCalculator(props.roundness)};
  height: ${(props) => (props?.height ? props?.height : 'fit-content')};
  position: ${(props) => (props.isPDPPage ? 'static' : 'relative')};
  cursor: ${(props) => props.cursor};
  font-size: ${(props) => props.fontSize || 14}px;
  ${(props) => props.pt && `padding-top: ${props.pt}`};
  ${(props) => props.pb && `padding-bottom: ${props.pb}`};
  ${(props) => props.pl && `padding-left: ${props.pl}`};
  ${(props) => props.pr && `padding-right: ${props.pr}`};
  ${(props) =>
    props.px &&
    `
      padding-left: ${props.px};
      padding-right: ${props.px};
    `}
  ${(props) =>
    props.py &&
    `
      padding-top: ${props.py};
      padding-bottom: ${props.py};
    `}
  ${(props) => props.mt && `margin-top: ${props.mt}`};
  ${(props) => props.mb && `margin-bottom: ${props.mb}`};
  ${(props) => props.ml && `margin-left: ${props.ml}`};
  ${(props) => props.mr && `margin-right: ${props.mr}`};
  ${(props) =>
    props.mx &&
    `
      margin-left: ${props.mx}${props.mx !== 'auto' && ``};
      margin-right: ${props.mx}${props.mx !== 'auto' && ``};
    `}
  ${(props) =>
    props.my &&
    `
      margin-top: ${props.my}${props.my !== 'auto' && ``};
      margin-bottom: ${props.my}${props.my !== 'auto' && ``};
    `}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.borderColor && `border-color: ${props.borderColor}`}
`;

export const CentralText = styled.span`
  display: flex;
  position: ${(props) => (props.isPDPPage ? 'static' : 'relative')};
  color: inherit;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || 14}px;
  width: 100%;
  white-space: nowrap;
  user-select: none;
`;

export const InteractionComponent = styled.span`
  position: ${(props) => (props.isPDPPage ? 'static' : 'absolute')};
  display: flex;
  align-items: center;
  height: 100%;
  width: ${(props) => props.width || '20px'};
  cursor: pointer;
  padding: 0 4px;
  ${(props) => props.position};

  & > div {
    width: 12px;

    & > svg {
      width: 100%;
    }
  }
`;
