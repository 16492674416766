import { isMobileDevice } from '@/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useSSRSelector } from '../redux';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { isMobileByUA } from '@/utils/operatingSystem';

interface IProps {
  customDeviceCheck?: (...args) => void;
}

interface IDeviceType {
  deviceType: 'mobile' | 'desktop';
}

export function useGetDeviceType(props = undefined): IDeviceType {
  // eslint-disable-next-line
  const { customDeviceCheck } = (props || {}) as IProps;
  const userAgent = useSSRSelector((state) => state.commonReducer.userAgent);
  const isInitialRequestByMobile = isMobileByUA(userAgent);
  const isMobile = IS_SERVER ? isInitialRequestByMobile : isMobileDevice();
  return { deviceType: isMobile ? 'mobile' : 'desktop' };
}
