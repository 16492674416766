import { useEffect } from "react"
import style from './globalLoader.module.scss'
import { twMerge } from "tailwind-merge";
import { useSSRSelector } from "src/redux/ssrStore";
import LoaderIcon from "src/components/Common/LoaderIcon";

export const GlobalLoader = (props) => {
  const {type, color} = props
  const store = useSSRSelector((state) => state.storeReducer.store);

  useEffect(() => {
    document.documentElement.style.setProperty('--loader-color', color);
  }, [color])

  switch (type) {
    case 'logo1' : {
        return (
          <div className={twMerge(style.logo1)}>
            <img src = {store.logo_image} style = {{height: '35px', width: '35px'}}/>
          </div>
        )
    }
    case 'logo2': {
      return (
        <div className={twMerge(style.logo2)}>
           <img src = {store.logo_image} style = {{height: '35px', width: '35px'}}/>
        </div>
      )
    }
    case 'loader1': {
      return (
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={'35px'}
          height={'35px'}
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
        >
          <path
            fill={color}
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      )
    }
    case 'loader2': {
      return (
        <svg
        version="1.1"
        id="L5"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={'35px'}
        height={'35px'}
        viewBox="-20 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <circle fill={color} stroke="none" cx="6" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={color} stroke="none" cx="30" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={color} stroke="none" cx="54" cy="50" r="6">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>    
      )
    }
    case 'loader3': {
      return (
        <div className={twMerge(style.loader3, 'tw-h-[35px] tw-w-[35px]')}></div>
      )
    }
    case 'loader4': {
      return (
        <svg
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={'35px'}
        height={'35px'}
        viewBox="-20 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <circle fill={color} stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={color} stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={color} stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
      )
    }
    case 'loader5': {
      return <div className={twMerge(style.loader5, 'tw-h-[16px] tw-w-[35px]')}></div>
    }
    default : {
      return <LoaderIcon {...props} />
    }
  }
}