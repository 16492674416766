import React from 'react';
import { CartButtonBody, CentralText, InteractionComponent } from './CartButton.styles';
import { MinusIcon } from '@/assets/svgExports/MinusIcon';
import { PlusIcon } from '@/assets/svgExports/PlusIcon';
import { useSSRSelector } from '@/redux/ssrStore';

const BUTTON_ACTION = {
  INCREMENT: 'inc',
  DECREMENT: 'dec',
};

const CartButton = (props) => {
  const { px = '1rem', py = '0.3rem' } = props;
  const {
    quantity,
    fontSize = 20,
    onChange,
    disableAddition = false,
    isPDPPage,
    ...args
  } = props;

  const storeInfo = useSSRSelector((state) => state.storeReducer.store);

  const onChangeBtn = (e, type, isLabel = false) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    if (isLabel && quantity) return;
    if (type === BUTTON_ACTION.INCREMENT && disableAddition) return;
    onChange?.(type);
  };

  return (
    <CartButtonBody
      roundness={storeInfo?.theme?.roundness}
      px={px}
      py={py}
      centered={quantity === 0 || quantity === undefined}
      isPDPPage={isPDPPage}
      cursor={quantity === 0 ? 'pointer' : 'default'}
      {...args}
    >
      {quantity === 0 || quantity === undefined ? (
        <CentralText
          onClick={(e) => onChangeBtn(e, 'add', true)}
          quantity={quantity}
          fontSize={fontSize}
          isPDPPage={isPDPPage}
        >
          {props.label ? props.label : isPDPPage ? 'Add to Cart' : 'ADD'}
        </CentralText>
      ) : (
        <>
          {props.leftButton ? (
            props.leftButton
          ) : (
            <InteractionComponent
              position="left:0px;"
              onClick={(e) => onChangeBtn(e, BUTTON_ACTION.DECREMENT)}
              isPDPPage={isPDPPage}
            >
              <MinusIcon />
            </InteractionComponent>
          )}
          <CentralText
            isPDPPage={isPDPPage}
            quantity={quantity === 0}
            fontSize={fontSize}
          >
            {/* change for only variant modal qty button */}
            {quantity || 0}
          </CentralText>
          {props.rightButton ? (
            props.rightButton
          ) : (
            <InteractionComponent
              position="right:0px;"
              onClick={(e) => onChangeBtn(e, BUTTON_ACTION.INCREMENT)}
              isPDPPage={isPDPPage}
            >
              <div
                className={`dib flex items-center
                  ${disableAddition ? 'o-20' : 'o-100'}
                `}
              >
                <PlusIcon />
              </div>
            </InteractionComponent>
          )}
        </>
      )}
    </CartButtonBody>
  );
};

export default CartButton;
