import { useWidgetDndContextData } from "@/context/WidgetDndContext";
import { MAX_MOBILE_WIDTH } from "@/utils/constants";
import { deviceWidth } from "@/utils/deviceWidth";

export const isMobileDevice = ()  => {
    const {
        widgetContextState: { previewDevice },
      // eslint-disable-next-line react-hooks/rules-of-hooks
      } = useWidgetDndContextData();
    
    const isDeviceMobile = deviceWidth <= MAX_MOBILE_WIDTH;
    const isMobile = isDeviceMobile || previewDevice === 'mobile';
    return isMobile;
}