/**
 * Pass the search params keys that you want to fetch in an array
 * It will return an object with the key-value pair
 * @param {array} arrayOfParamKeys []
 * @return {array} destructure the array in the same order as their respective keys
 */
export function getUrlParams(arrayOfParamKeys) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlData = {};
  for (const key of arrayOfParamKeys) {
    urlData[key] = urlParams.get(key);
  }
  return urlData;
}
